import { Paths } from 'constants/routes';
import * as H from 'history';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface ConditionalState {
	conditional: boolean;
}

type ConditionalLocationState<T = Record<string, never>> = T & ConditionalState;

export const useConditionalLocation = <T = Record<string, never>>(
	route: string = Paths.DASHBOARD,
): H.Location<ConditionalLocationState<T> | undefined> => {
	const history = useHistory();
	const location = useLocation<ConditionalLocationState<T>>();
	useEffect(() => {
		const response = location.state?.conditional;
		if (!response) {
			history.replace(route);
		}
	}, [history, location, route]);
	return location;
};

interface ConditionalRedirectRoutes {
	passedRoute?: string;
	failedRoute?: string;
}

type ConditionalRedirectProps<T = Record<string, never>> = T & ConditionalRedirectRoutes;

export const useConditionalRedirect = <T = Record<string, never>>(
	condition: boolean | undefined,
	{ passedRoute, failedRoute, ...props }: ConditionalRedirectProps<T>,
): void => {
	const history = useHistory<ConditionalLocationState>();
	useEffect(() => {
		if (condition !== undefined) {
			if (condition && passedRoute !== undefined) {
				// @ts-ignore - TODO make sure the typing is not wrong
				history.push(passedRoute, { conditional: true, ...props });
			}

			if (condition === false && failedRoute !== undefined) {
				// @ts-ignore - TODO make sure the typing is not wrong
				history.push(failedRoute, { conditional: true, ...props });
			}
		}
	}, [history, condition, passedRoute, failedRoute, props]);
};

export const useConditionalHistory = (): H.History<ConditionalLocationState> => {
	const history = useHistory<ConditionalLocationState>();

	return history;
};
