import { useIsAuthenticated } from '@azure/msal-react';
import { Paths } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { useBackLink } from 'hooks/useBackLink';
import { DirectionsArrowLeft, TextLink } from '@woonstad/design-system';
import { Link } from 'react-router-dom';


/**
 * In case the topLink is set for either authentication statusses, use that instead of the default.
 * When passing false to either status, the link will not be shown.
 */
export type TopLink = {
	isAuthenticated?: {
		text: string;
		link: string;
	} | false;
	isNotAuthenticated?: {
		text: string;
		link: string;
	} | false;
};

type Props = {
	/**
	 * In case the topLink is set for either authentication statusses, use that instead of the default
	 * When passing false to either status, the link will not be shown.
	 */
	topLink?: TopLink;
}

const BackDashboardLink = ({ topLink }: Props) => {
	const { t } = useTranslation();
	const backLinkPath = useBackLink();
	const isAuthenticated = useIsAuthenticated();

	const link = (() => {
		if (isAuthenticated && topLink && 'isAuthenticated' in topLink) {
			return topLink.isAuthenticated === false ? undefined : topLink.isAuthenticated?.link;
		}

		if (!isAuthenticated && topLink && 'isNotAuthenticated' in topLink) {
			return topLink.isNotAuthenticated === false ? undefined : topLink.isNotAuthenticated?.link;
		}

		return isAuthenticated ? backLinkPath ?? Paths.DASHBOARD : undefined;
	})();

	const text = (() => {
		if (isAuthenticated && topLink && 'isAuthenticated' in topLink) {
			return topLink.isAuthenticated === false ? undefined : topLink.isAuthenticated?.text;
		}

		if (!isAuthenticated && topLink && 'isNotAuthenticated' in topLink) {
			return topLink.isNotAuthenticated === false ? undefined : topLink.isNotAuthenticated?.text;
		}

		return isAuthenticated ? t('MijnStadswonen') : t('Terug');
	})();

	if (
		(topLink && 'isAuthenticated' in topLink)
		|| (topLink && 'isNotAuthenticated' in topLink)
	) {
		if (isAuthenticated && topLink.isAuthenticated === false) return null;
		if (!isAuthenticated && topLink.isNotAuthenticated === false) return null;
	}

	return (
		<header className='flex flex-col gap-5 mb-5'>
			<TextLink
				iconLeft={DirectionsArrowLeft}
				forceEnabled
				testid='back-button'
			>
				<Link to={link ? link : 'javascript:history.back()'}>
					{text}
				</Link>
			</TextLink>
		</header>
	);
};

export default BackDashboardLink;
