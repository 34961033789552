import { useQuery } from '@tanstack/react-query';
import { MaintenanceService } from '../../../../Shared.Frontend/generated-portal-stadswonen/services/MaintenanceService';

export const useRequestKeyPrices = () => {
	const { data, isSuccess, isLoading } = useQuery({
		queryKey: ['request-key-prices'],
		queryFn: MaintenanceService.maintenanceGetKeyPrices,
	});
	return {
		success: isSuccess,
		isLoading: isLoading,
		prices: data,
	};
};
