import clsx from 'clsx';
import useTransitionHeight from 'hooks/useTransitionHeight';
import { FunctionComponent } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { TransitionChildren } from 'react-transition-group/Transition';
import './SkeletonSwitch.scss';

interface Props {
  skeleton: TransitionChildren;
  children: TransitionChildren;
  showContent: boolean;
  className?: string;
}

const SkeletonSwitch: FunctionComponent<Props> = ({ skeleton, children, showContent, className }) => {
	const [height, onSetHeight, onResetHeight] = useTransitionHeight();

	const classes = clsx('skeleton-switch', className);
	return (
		<div
			className={classes}
			style={{ height }}
		>
			<SwitchTransition mode='out-in'>
				<CSSTransition
					timeout={200}
					key={showContent ? 'content' : 'loader'}
					classNames='skeleton-switch__transition-'
					onEntering={onSetHeight}
					onEntered={onResetHeight}
					onExit={onSetHeight}
				>
					{showContent ? children : skeleton}
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
};

export default SkeletonSwitch;
