import { Suspense, useEffect } from 'react';
import Routing, { allPublicPaths } from 'Routing';
import { useDataConfig } from 'hooks/store/useDataConfig';
import { useMsal, useMsalAuthentication, useIsAuthenticated } from '@azure/msal-react';
import { BrowserAuthError, InteractionRequiredAuthError, InteractionStatus, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest } from 'auth/config';
import { useQuery } from '@tanstack/react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Paths } from 'constants/routes';
import { MsalProvider } from '@azure/msal-react';
import { CustomNavigationClient } from 'utils/navigationClient';
import getDomain from '../../Shared.Frontend/utils/getDomain';
import { useCookies } from 'react-cookie';
import { openAPIMutate, openAPISet } from 'utils/openAPI';
import LoadingPage from 'components/loading-page/LoadingPage';

const App = ({ pca }: { pca: PublicClientApplication }) => {
	const history = useHistory();

	// This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
	const navigationClient = new CustomNavigationClient(history);
	pca.setNavigationClient(navigationClient);

	// useMsalAuthentication must be rendered only once in the tree
	// Otherwise all will make auth calls to microsoft
	const {
		login,
		result,
		acquireToken,
		error,
	} = useMsalAuthentication(
		// Using Silent for the original request - otherwise a loop will be create
		// The useEffect with the InteractionRequiredAuthError login with Redirect is neccesary
		InteractionType.Silent,
		loginRequest,
	);

	const { instance, inProgress } = useMsal();

	const isOnPublicPage = useRouteMatch(allPublicPaths);

	const isOnLoggedOutPage = useRouteMatch(allPublicPaths.filter((path) => (
		// Login and logged out are special cases as they are public but should trigger the auth flow
		path === Paths.LOGGED_OUT
	)));

	useEffect(() => {
		if (isOnPublicPage || isOnLoggedOutPage) {
			return;
		}

		if (
			// InteractionRequiredAuthError is used in all the examples
			error instanceof InteractionRequiredAuthError
			// We added BrowserAuthError because it is thrown more often
			// This fixes an issue where the login would not be triggered when transitioning from a public to a private page
			|| error instanceof BrowserAuthError
		) {
			// Triggered if the Silent login from useMsalAuthentication - failed.
			// This will kick off the user interaction login process (username/password screens)
			login(InteractionType.Redirect, loginRequest);
			return;
		}

		if (error?.message.includes('user_cancelled')) {
			history.push(Paths.LOGIN_FAILED);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, isOnPublicPage]);

	useEffect(() => {
		if (result) {
			if (result.account) {
				instance.setActiveAccount(result.account);
			} else {
				login(InteractionType.Redirect, loginRequest);
			}
		}
	}, [instance, result, login]);

	// We reexport the data in the useToken hook - fetching data here as useMsalAuthentication can only be rendered once
	const { data, isLoading } = useQuery({
		queryKey: ['token'],
		queryFn: async () => {
			const authenticationResult = await acquireToken(InteractionType.Redirect, loginRequest);
			openAPISet('TOKEN', authenticationResult?.accessToken);

			// Need to add access token to both header as well as cookie
			// The documents are hosted on the same domain as website, and only served via direct GET request so header is not possible. Using cookie for this scenario
			// For all the API calls (via gateway), we use header. This is required because setting a cookie for the gateway domain is not possible from website domain
			openAPIMutate('HEADERS', (currentHeaders) => ({ ...currentHeaders,
				'Authorization': `Bearer ${authenticationResult?.accessToken ?? ''}`,
			}));

			return authenticationResult;
		},
		enabled: inProgress === InteractionStatus.None && !isOnLoggedOutPage,
	});

	const [, setCookie, removeCookie] = useCookies(['x-auth-token']);
	const isAuthenticated = useIsAuthenticated();

	useEffect(() => {
		const domain = `.${getDomain(location.host)}`;

		if (!isAuthenticated) {
			removeCookie('x-auth-token', {
				path: '/',
				domain,
			});
		}

		// Make the access token available for other stadswonenrotterdam.nl websites, such as www.stadswonenrotterdam.nl,
		// needed because of Azure B2C login.
		if (isAuthenticated && data?.accessToken) {
			setCookie('x-auth-token', data.accessToken, {
				path: '/',
				domain,
				...(domain.includes('localhost') ? {} : { secure: true }), // Only set secure cookie on production (not localhost)
				// ...(domain.includes('localhost') ? {} : { httpOnly: true }), // Only set httpOnly cookie on production (not localhost
				...(data?.expiresOn ? { expires: data?.expiresOn } : {}),
			});
		}
	}, [removeCookie, isAuthenticated, data, setCookie]);

	useDataConfig();

	if (
		(isLoading && !isOnLoggedOutPage)
		|| inProgress !== InteractionStatus.None
	) {
		return <LoadingPage />;
	}

	return (
		<MsalProvider instance={pca}>
			<Suspense fallback=''>
				<Routing />
			</Suspense>
		</MsalProvider>
	);
};

export default App;
