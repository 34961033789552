const DIACRITIC_CHARACTERS =
  'ÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïñòóôõöùúûüýÿĀāĂăĄąĆćĈĉĊċČčĎďĒēĔĕĖėĘęĚěĜĝĞğĠġĢģĤĥĨĩĪīĬĭĮįİĴĵĶķĹĺĻļĽľŁłŃńŅņŇňŌōŎŏŐőŔŕŖŗŘřŚśŜŝŞşŠšŢţŤťŨũŪūŬŭŮůŰűŲųŴŵŶŷŸŹźŻżŽžÆæßøØ';
const ALPHABETIC_WITH_PUNCTUATION_CHARACTERS = `a-zA-Z&\\-+=()_$?’!@*{}[\\]:;.,/|\`'~#"\\\\ ${DIACRITIC_CHARACTERS}`;
const ALPHABETIC_CHARACTERS = `a-zA-Z\\ ${DIACRITIC_CHARACTERS}`;

export const NAME_CHARACTERS = new RegExp('^[a-zA-Z\\ -]*$');
export const ALPHANUMERIC_WITH_PUNCTUATION_AND_NEWLINES = new RegExp(
	`^[0-9\n${ALPHABETIC_WITH_PUNCTUATION_CHARACTERS}]*$`,
);

export const ALPHANUMERIC_WITH_PUNCTUATION = new RegExp(`^[0-9${ALPHABETIC_WITH_PUNCTUATION_CHARACTERS}]*$`);
export const ALPHABETIC_WITH_PUNCTUATION = new RegExp(`^[${ALPHABETIC_WITH_PUNCTUATION_CHARACTERS}]*$`);
export const ALPHABETIC = new RegExp(`^[${ALPHABETIC_CHARACTERS}]*$`);
export const ALPHANUMERIC = /^([a-zA-Z0-9]*)$/;

export const CITY = new RegExp(`^[a-zA-Z0-9,\\-'" ${DIACRITIC_CHARACTERS}]*$`);
export const STREET = new RegExp(`^[a-zA-Z0-9,\\-'"./ ${DIACRITIC_CHARACTERS}]*$`);
export const PHONE = /^(([0-9]{10}$)|(0800|090[0-9]{1})[-]([0-9]{4,7}$)|([+]).[0-9]{0,20}$)/;
export const PHONE_REGISTRATION = /^(([0])([0-9]{9}$))|^(((?!\+$)([+]))([0-9]{0,20}$))/;

export const NUMERIC = /^([0-9])*$/;
export const DECIMAL = /^\d*$|(?=^.*$)^\d+(\.|,)\d*$/g;
export const PERCENTAGE = /^\d*$|(?=^.*$)^\d+(,)\d*$/g;
export const ZIP_CODE_INTERNATIONAL = /^([a-zA-Z0-9 -]*)$/;
export const ZIP_CODE = /^[0-9]{4} [a-zA-Z]{2}$/;
export const BIRTHDAY = /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/;
export const DATE = /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/;
export const CURRENCY_EURO = /(?=.*?\d)^\$?(([1-9]\d{0,2}(.\d{3})*)|\d+)?(,\d{1,2})?$/g;

export const PASSWORD = /^[a-zA-Z0-9&\-+=()_$?!@*\\{}[\]:;.,/\\|`'~#"!^_±§]/;
export const PASSWORD_LENGTH = /^(?=.{6,})/;
export const PASSWORD_LETTERS = /^(?=.*[a-z])(?=.*[A-Z])/;
export const PASSWORD_SPECIAL_CHARACTERS = /^(?=.*[&\-+=()_$?!@*\\{}[\]:;.,/\\|`'~#"!^_])/;
export const PASSWORD_REQUIREMENTS = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[&\-+=()_$?!@*\\{}[\]:;.,/\\|`'~#"!^_])(?=.{6,})/;

export const UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/;
export const EXTERNAL_LINK = /^https?:\/\//;

export const HTML_TAGS = /<(.|\n)*?>/g;
