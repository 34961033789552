import { ReactNode, useEffect } from 'react';
import BackDashboardLink, { TopLink } from 'components/back-dashboard-link/BackDashboardLink';
import { Typography } from '@woonstad/design-system';
import { Trans, useTranslation } from 'react-i18next';
import safeWindow from 'utils/safeWindow';
import clsx from 'clsx';

type Props = {
	children: ReactNode;
	title: string;
	subtag?: string;
	topLink?: TopLink;
	isWhite?: boolean;
	testid?: string;
	id?: string;
}

const Layout = ({
	children,
	title,
	subtag,
	topLink,
	isWhite = false,
	testid,
	id,
}: Props) => {
	const { t } = useTranslation();

	useEffect(() => {
		if (!safeWindow.document) return;
		safeWindow.document.title = `${t('MijnStadswonen')}${title ? ` - ${t(title)}` : ''}`;
	}, [title, t]);

	return (
		<div
			id={id}
			className={clsx(
				'px-4 py-6 sm:px-10 sm:py-10 bg-surface-form text-base-primary-on-light',
				isWhite && 'bg-surface-default',
			)}
		>

			<BackDashboardLink topLink={topLink} />

			<main className='flex flex-col gap-6 sm:gap-8 max-w-[37.5rem]'>
				<div
					className='grid-1-sm mt-3'
					data-testid={testid}
				>
					{subtag && (
						<Typography
							type='label'
							variant='label-regular'
							className='text-base-secondary-on-light'
						>
							<Trans>{subtag}</Trans>
						</Typography>
					)}

					<Typography
						type='h1'
						variant='title-page'
					>
						<Trans>{title}</Trans>
					</Typography>
				</div>

				{children}
			</main>
		</div>
	);
};

export default Layout;
