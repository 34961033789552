import { FunctionComponent, lazy } from 'react';
import CenterBoxedContainer from 'components/center-boxed-container/CenterBoxedContainer';
import { LanguageProvider } from 'components/language-provider/LanguageProvider';
import PageRoutesContainer from 'components/page-routes-container/PageRoutesContainer';
import AuthPageTransition from 'components/transitions/AuthPageTransition';
import { Paths } from 'constants/routes';
import { useGoogleTagManagerVirtualPageView } from 'hooks/app/useGoogleTagManager';
import useScrollRestoration from 'hooks/app/useScrollRestoration';
import { useUserIsContractor } from 'hooks/store/useUserRoles';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import useRedirectCallback from 'hooks/app/useRedirectCallback';
import { ValueOf } from '../../Shared.Frontend/types/utils';
import { FeatureFlags, useFeatureFlagsPresent } from 'hooks/useFeatureFlag';
import useNewCustomerRouting from 'hooks/useNewCustomerRouting';
import useMultiCustomerRouting from 'hooks/useMultiCustomerRouting';
import { RouteItem } from 'hooks/useAliasRouting';
import { safeEnv } from 'utils/safeEnv';
import useAliasRouting from 'hooks/useAliasRouting';

import RequestKey from 'pages/request-key/RequestKey';
import RequestKeyPaymentReturn from 'pages/request-key/payment-return/RequestKeyPaymentReturn';

const LoggedOut = lazy(() => import('pages/logged-out/LoggedOut'));
const LoginFailed = lazy(() => import('pages/login-failed/LoginFailed'));

const ChooseAccount = lazy(() => import('pages/choose-account/ChooseAccount'));
const SignupPayment = lazy(() => import('pages/signup-payment/SignupPayment'));

const Dashboard = lazy(() => import('pages/dashboard/Dashboard'));
const Notifications = lazy(() => import('pages/notifications/Notifications'));
const UserProfile = lazy(() => import('pages/user-profile/UserProfile'));
const HomeValuePoints = lazy(() => import('pages/home-value-points/HomeValuePoints'));
const Reactions = lazy(() => import('pages/reactions/Reactions'));
const Maintenance = lazy(() => import('pages/maintenance/Maintenance'));
const MailPreferences = lazy(() => import('pages/mail-preferences/MailPreferences'));

const RequestUrgency = lazy(() => import('pages/request-urgency/RequestUrgency'));
const RequestUrgencyPaymentReturn = lazy(() => import('pages/request-urgency/RequestUrgencyPaymentReturn'));

const PaymentPlan = lazy(() => import('pages/payment-plan/PaymentPlan'));
const PaymentPlanConfirmation = lazy(() => import('pages/payment-plan/payment-plan-confirmation/PaymentPlanConfirmation'));
const PaymentPlanError = lazy(() => import('pages/payment-plan/payment-plan-error/PaymentPlanError'));

const PaymentReturn = lazy(() => import('pages/payment-return/PaymentReturn'));

const RentIncreaseObjection = lazy(() => import('pages/rent-increase-objection/RentIncreaseObjection'));
const RentIncreaseObjectionConfirmation = lazy(() => import('pages/rent-increase-objection/RentIncreaseObjectionConfirmation'));
const RentIncreaseObjectionError = lazy(() => import('pages/rent-increase-objection/RentIncreaseObjectionError'));

const CancelContract = lazy(() => import('pages/cancel-contract/CancelContract'));
const CancelContractConfirmation = lazy(() => import('pages/cancel-contract/cancel-contract-confirmation/CancelContractConfirmation'));
const CancelContractError = lazy(() => import('pages/cancel-contract/cancel-contract-error/CancelContractError'));

const AddRoommate = lazy(() => import('pages/add-roommate/AddRoommate'));
const AddRoommateConfirmation = lazy(() => import('pages/add-roommate/add-roommate-confirmation/AddRoommateConfirmation'));
const AddRoommateError = lazy(() => import('pages/add-roommate/add-roommate-error/AddRoommateError'));

const InwonerVertrekt = lazy(() => import('pages/inwoner-vertrekt/InwonerVertrekt'));
const InwonerVertrektSuccess = lazy(() => import('pages/inwoner-vertrekt/Success'));
const InwonerVertrektError = lazy(() => import('pages/inwoner-vertrekt/Error'));

const SleutelAanvragenFormKeuze = lazy(() => import('pages/sleutel-aanvragen/SleutelAanvragenFormKeuze'));
const SleutelAanvragenFormDetails = lazy(() => import('pages/sleutel-aanvragen/SleutelAanvragenFormDetails'));
const SleutelAanvragenBetalingInBehandeling = lazy(() => import('pages/sleutel-aanvragen/SleutelAanvragenBetalingInBehandeling'));
const SleutelAanvragenBetalingError = lazy(() => import('pages/sleutel-aanvragen/ErrorBetaling'));
const SleutelAanvragenSuccess = lazy(() => import('pages/sleutel-aanvragen/Success'));
const SleutelAanvragenError = lazy(() => import('pages/sleutel-aanvragen/Error'));

const RequestSublease = lazy(() => import('pages/request-sublease/RequestSublease'));
const RequestSubleaseConfirmation = lazy(() => import('pages/request-sublease/request-sublease-confirmation/RequestSubleaseConfirmation'));
const RequestSubleaseError = lazy(() => import('pages/request-sublease/request-sublease-error/RequestSubleaseError'));

const Campaign = lazy(() => import('pages/campaign/Campaign'));
const CampaignAlreadySubscribed = lazy(() => import('pages/campaign/campaign-already-subscribed/CampaignAlreadySubscribed'));
const CampaignSubscribedSuccess = lazy(() => import('pages/campaign/campaign-subscribed-confirmation/CampaignSubscribedSuccess'));
const CampaignSubscribedError = lazy(() => import('pages/campaign/campaign-subscribed-error/CampaignSubscribedError'));
const CampaignUnqualified = lazy(() => import('pages/campaign/campaign-unqualified/CampaignUnqualified'));

const ChangeDirectDebitDate = lazy(() => import('pages/change-direct-debit-date/ChangeDirectDebitDate'));
const ChangeDirectDebitDateConfirmation = lazy(() => import('pages/change-direct-debit-date/change-direct-debit-date-confirmation/ChangeDirectDebitDateConfirmation'));
const ChangeDirectDebitDateError = lazy(() => import('pages/change-direct-debit-date/change-direct-debit-date-error/ChangeDirectDebitDateError'));

const OverlastMelden = lazy(() => import('pages/overlast-melden/OverlastMelden'));
const OverlastMeldenSuccess = lazy(() => import('pages/overlast-melden/Success'));
const OverlastMeldenError = lazy(() => import('pages/overlast-melden/Error'));

const SubmitReaction = lazy(() => import('pages/submit-reaction/SubmitReaction'));
const SubmitReactionConfirmation = lazy(() => import('pages/submit-reaction/submit-reaction-confirmation/SubmitReactionConfirmation'));
const SubmitReactionError = lazy(() => import('pages/submit-reaction/submit-reaction-error/SubmitReactionError'));

const GeneralContact = lazy(() => import('pages/general-contact/GeneralContact'));
const GeneralContactConfirmation = lazy(() => import('pages/general-contact/general-contact-confirmation/GeneralContactConfirmation'));
const GeneralContactError = lazy(() => import('pages/general-contact/general-contact-error/GeneralContactError'));

const RentalReferenceLetter = lazy(() => import('pages/rental-reference-letter/RentalReferenceLetter'));
const RentalReferenceLetterStart = lazy(() => import('pages/rental-reference-letter/RentalReferenceLetterStart'));
const RentalReferenceLetterConfirmation = lazy(() => import('pages/rental-reference-letter/RentalReferenceLetterConfirmation'));
const RentalReferenceLetterError = lazy(() => import('pages/rental-reference-letter/RentalReferenceLetterError'));

const HuurcontractKoppelen = lazy(() => import('pages/huurcontract-koppelen/HuurcontractKoppelen'));
const HuurcontractKoppelenConfirmation = lazy(() => import('pages/huurcontract-koppelen/confirmation/HuurcontractKoppelenConfirmation'));

const SignupWelcome = lazy(() => import('pages/signup-welcome/SignupWelcome'));
const SignupDetails = lazy(() => import('pages/signup-details/SignupDetails'));

const ChangeEmailMultiUser = lazy(() => import('pages/change-email-multi-user/ChangeEmailMultiUser'));
const ChangeEmailMultiUserConfirmation = lazy(() => import('pages/change-email-multi-user/confirmation/ChangeEmailMultiUserConfirmation'));

const NoAccess = lazy(() => import('pages/no-access/NoAccess'));
const NotFound = lazy(() => import('pages/not-found/NotFound'));

const DocumentView = lazy(() => import('pages/document-view/DocumentView'));

const WoonfraudeMelden = lazy(() => import('pages/woonfraude-melden/WoonfraudeMelden'));
const WoonfraudeMeldenSuccess = lazy(() => import('pages/woonfraude-melden/Success'));
const WoonfraudeMeldenError = lazy(() => import('pages/woonfraude-melden/Error'));

const OngedierteMelden = lazy(() => import('pages/ongedierte-melden/OngedierteMelden'));
const OngedierteMeldenSuccess = lazy(() => import('pages/ongedierte-melden/OngedierteMeldenConfirmation'));
const OngedierteMeldenError = lazy(() => import('pages/ongedierte-melden/OngedierteMeldenError'));

const ZorgOmJeBuur = lazy(() => import('pages/zorg-om-je-buur/ZorgOmJeBuur'));
const ZorgOmJeBuurConfirmation = lazy(() => import('pages/zorg-om-je-buur/ZorgOmJeBuurConfirmation'));
const ZorgOmJeBuurError = lazy(() => import('pages/zorg-om-je-buur/ZorgOmJeBuurError'));

const ChangeIban = lazy(() => import('pages/change-iban/ChangeIban'));
const ChangeIbanSuccess = lazy(() => import('pages/change-iban/ChangeIbanConfirmation'));
const ChangeIbanError = lazy(() => import('pages/change-iban/ChangeIbanError'));

const HuurderVertrekt = lazy(() => import('pages/huurder-vertrekt/HuurderVertrekt'));
const HuurderVertrektSuccess = lazy(() => import('pages/huurder-vertrekt/HuurderVertrektConfirmation'));
const HuurderVertrektError = lazy(() => import('pages/huurder-vertrekt/HuurderVertrektError'));

type PrivateRoute = {
	readonly path: ValueOf<typeof Paths>;
	readonly component: FunctionComponent;
	readonly featureflag?: {
		readonly featureName: FeatureFlags;
		readonly component: FunctionComponent;
	},
	readonly exact?: boolean;
};

// Do not forget to add pages to the copy-prerender.js script
// Commented the B2C pages because of the global stylesheet being loaded
export const b2cPages = [
	{
		path: Paths.LOGIN_B2C,
		// component: LoginB2C,
	},
	{
		path: Paths.REGISTER_B2C,
		// component: RegisterB2C,
	},
	{
		path: Paths.FORGOT_PASSWORD_B2C,
		// component: ForgotPasswordB2C,
	},
	{
		path: Paths.CHANGE_PASSWORD_B2C,
		// component: ChangePasswordB2C,
	},
	{
		path: Paths.INVITATION_B2C,
		// component: InvitationB2C,
	},
	{
		path: Paths.PROFILE_UPDATE_B2C,
		// component: ProfileUpdateB2C,
	},
	{
		path: Paths.LOGIN_SIMPLE_B2C,
		// component: LoginSimpleB2C,
	},
];

export const loginPublicRoutes = [
	{
		path: Paths.LOGGED_OUT,
		component: LoggedOut,
		redirectIfLoggedIn: Paths.DASHBOARD,
	},
	{
		path: Paths.LOGIN_FAILED,
		component: LoginFailed,
		redirectIfLoggedIn: Paths.DASHBOARD,
	},
];

export const aliasPublicRoutes: RouteItem[] = [
	{
		path: Paths.CREATE_ACCOUNT,
		to: safeEnv('B2C_CREATE_ACCOUNT') ?? '',
	},
];

export const centerBoxedPublicRoutes = [
	{
		path: Paths.CHOOSE_ACCOUNT,
		component: ChooseAccount,
	},
	{
		path: Paths.SIGN_UP_PAYMENT,
		component: SignupPayment,
		exact: true,
		redirectIfLoggedIn: Paths.DASHBOARD,
	},
];

const nonContractorFullPageRoutes: PrivateRoute[] = [
	{
		path: Paths.CAMPAIGN,
		component: Campaign,
		exact: true,
	},
	{
		path: Paths.CAMPAIGN_UNQUALIFIED,
		component: CampaignUnqualified,
	},
	{
		path: Paths.CAMPAIGN_ALREADY_SUBSCRIBED,
		component: CampaignAlreadySubscribed,
	},
	{
		path: Paths.CAMPAIGN_SUCCESS,
		component: CampaignSubscribedSuccess,
	},
	{
		path: Paths.CAMPAIGN_ERROR,
		component: CampaignSubscribedError,
	},
];

const contractorFullPageRoutes: PrivateRoute[] = [
	{
		path: Paths.HOME_VALUATION_POINTS,
		component: HomeValuePoints,
		exact: true,
	},
	{
		path: Paths.ADD_ROOMMATE,
		component: AddRoommate,
		exact: true,
	},
	{
		path: Paths.ADD_ROOMMATE_CONFIRMATION,
		component: AddRoommateConfirmation,
		exact: true,
	},
	{
		path: Paths.ADD_ROOMMATE_ERROR,
		component: AddRoommateError,
		exact: true,
	},
	{
		path: Paths.INWONER_VERTREKT,
		component: InwonerVertrekt,
		exact: true,
	},
	{
		path: Paths.INWONER_VERTREKT_SUCCESS,
		component: InwonerVertrektSuccess,
		exact: true,
	},
	{
		path: Paths.INWONER_VERTREKT_ERROR,
		component: InwonerVertrektError,
		exact: true,
	},
	{
		path: Paths.REQUEST_KEY,
		component: RequestKey,
		exact: true,
	},
	{
		path: Paths.REQUEST_KEY_PAYMENT_RETURN,
		component: RequestKeyPaymentReturn,
		exact: true,
	},
	{
		path: Paths.SLEUTEL_AANVRAGEN,
		component: SleutelAanvragenFormKeuze,
		exact: true,
	},
	{
		path: Paths.SLEUTEL_AANVRAGEN_DETAILS,
		component: SleutelAanvragenFormDetails,
		exact: true,
	},
	{
		path: Paths.SLEUTEL_AANVRAGEN_BETALING_IN_BEHANDELING,
		component: SleutelAanvragenBetalingInBehandeling,
		exact: true,
	},
	{
		path: Paths.SLEUTEL_AANVRAGEN_BETALING_ERROR,
		component: SleutelAanvragenBetalingError,
		exact: true,
	},
	{
		path: Paths.SLEUTEL_AANVRAGEN_SUCCESS,
		component: SleutelAanvragenSuccess,
		exact: true,
	},
	{
		path: Paths.SLEUTEL_AANVRAGEN_ERROR,
		component: SleutelAanvragenError,
		exact: true,
	},
	{
		path: Paths.CANCEL_CONTRACT,
		exact: true,
		component: CancelContract,
		// <PhoneNumberCheckProvider>
		// </PhoneNumberCheckProvider>
	},
	{
		path: Paths.CANCEL_CONTRACT_CONFIRMATION,
		component: CancelContractConfirmation,
		exact: true,
	},
	{
		path: Paths.CANCEL_CONTRACT_ERROR,
		component: CancelContractError,
		exact: true,
	},
	{
		path: Paths.PAYMENT_PLAN,
		component: PaymentPlan,
		exact: true,
	},
	{
		path: Paths.PAYMENT_PLAN_SUCCESS,
		component: PaymentPlanConfirmation,
	},
	{
		path: Paths.PAYMENT_PLAN_ERROR,
		component: PaymentPlanError,
	},
	{
		path: Paths.REQUEST_SUBLEASE,
		component: RequestSublease,
		exact: true,
	},
	{
		path: Paths.REQUEST_SUBLEASE_SUCCESS,
		component: RequestSubleaseConfirmation,
	},
	{
		path: Paths.REQUEST_SUBLEASE_ERROR,
		component: RequestSubleaseError,
	},
	{
		path: Paths.MAINTENANCE,
		component: Maintenance,
		exact: true,
	},
	{
		path: Paths.CHANGE_DIRECT_DEBIT_DATE,
		component: ChangeDirectDebitDate,
		exact: true,
	},
	{
		path: Paths.CHANGE_DIRECT_DEBIT_DATE_CONFIRMATION,
		component: ChangeDirectDebitDateConfirmation,
		exact: true,
	},
	{
		path: Paths.CHANGE_DIRECT_DEBIT_DATE_ERROR,
		component: ChangeDirectDebitDateError,
		exact: true,
	},
	{
		path: Paths.OVERLAST_MELDEN,
		component: OverlastMelden,
		exact: true,
	},
	{
		path: Paths.OVERLAST_MELDEN_SUCCESS,
		component: OverlastMeldenSuccess,
	},
	{
		path: Paths.OVERLAST_MELDEN_ERROR,
		component: OverlastMeldenError,
	},
	{
		path: Paths.GENERAL_CONTACT,
		component: GeneralContact,
		exact: true,
	},
	{
		path: Paths.GENERAL_CONTACT_SUCCESS,
		component: GeneralContactConfirmation,
		exact: true,
	},
	{
		path: Paths.GENERAL_CONTACT_ERROR,
		component: GeneralContactError,
		exact: true,
	},
	{
		path: Paths.RENTAL_REFERENCE_LETTER,
		component: RentalReferenceLetter,
		exact: true,
	},
	{
		path: Paths.RENTAL_REFERENCE_LETTER_START,
		component: RentalReferenceLetterStart,
		exact: true,
	},
	{
		path: Paths.RENTAL_REFERENCE_LETTER_SUCCESS,
		component: RentalReferenceLetterConfirmation,
		exact: true,
	},
	{
		path: Paths.RENTAL_REFERENCE_LETTER_ERROR,
		component: RentalReferenceLetterError,
		exact: true,
	},
	{
		path: Paths.RENT_INCREASE_OBJECTION,
		component: RentIncreaseObjection,
		exact: true,
	},
	{
		path: Paths.RENT_INCREASE_OBJECTION_CONFIRMATION,
		component: RentIncreaseObjectionConfirmation,
		exact: true,
	},
	{
		path: Paths.RENT_INCREASE_OBJECTION_ERROR,
		component: RentIncreaseObjectionError,
		exact: true,
	},
	{
		path: Paths.WOONFRAUDE_MELDEN,
		component: WoonfraudeMelden,
		exact: true,
	},
	{
		path: Paths.WOONFRAUDE_MELDEN_SUCCESS,
		component: WoonfraudeMeldenSuccess,
		exact: true,
	},
	{
		path: Paths.WOONFRAUDE_MELDEN_ERROR,
		component: WoonfraudeMeldenError,
		exact: true,
	},
	{
		path: Paths.HUURDER_VERTREKT,
		component: HuurderVertrekt,
	},
	{
		path: Paths.HUURDER_VERTREKT_SUCCESS,
		component: HuurderVertrektSuccess,
	},
	{
		path: Paths.HUURDER_VERTREKT_ERROR,
		component: HuurderVertrektError,
	},
];

const signupRoutes: PrivateRoute[] = [
	{
		path: Paths.SIGNUP_WELCOME,
		component: SignupWelcome,
		exact: true,
	},
	{
		path: Paths.SIGNUP_DETAILS,
		component: SignupDetails,
		exact: true,
	},
];

const privateRoutesAll: PrivateRoute[] = [
	{
		path: Paths.DASHBOARD,
		component: Dashboard,
	},
	{
		path: Paths.DASHBOARD_RUIMTE,
		component: Dashboard,
	},
	{
		path: Paths.DASHBOARD_PARKEERPLAATS,
		component: Dashboard,
	},
	{
		path: Paths.DASHBOARD_HUISHOUDEN,
		component: Dashboard,
	},
	{
		path: Paths.DASHBOARD_HUUR_BETALINGEN,
		component: Dashboard,
	},
	{
		path: Paths.DASHBOARD_DOCUMENTEN,
		component: Dashboard,
	},
	{
		path: Paths.USER_PROFILE,
		component: UserProfile,
		exact: true,
	},
	{
		path: Paths.PAYMENT_RETURN,
		component: PaymentReturn,
	},
	{
		path: Paths.NOTIFICATIONS,
		component: Notifications,
	},
	{
		path: Paths.MAIL_PREFERENCES,
		component: MailPreferences,
	},
	{
		path: Paths.REQUEST_URGENCY,
		component: RequestUrgency,
	},
	{
		path: Paths.REQUEST_URGENCY_PAYMENT_RETURN,
		component: RequestUrgencyPaymentReturn,
	},
	{
		path: Paths.HUURCONTRACT_KOPPELEN,
		component: HuurcontractKoppelen,
	},
	{
		path: Paths.HUURCONTRACT_KOPPELEN_CONFIRMATION,
		component: HuurcontractKoppelenConfirmation,
	},
	{
		path: Paths.CHANGE_EMAIL_MULTI_USER,
		component: ChangeEmailMultiUser,
	},
	{
		path: Paths.CHANGE_EMAIL_MULTI_USER_CONFIRMATION,
		component: ChangeEmailMultiUserConfirmation,
	},
	{
		path: Paths.REACTIONS,
		component: Reactions,
	},
	{
		path: Paths.SUBMIT_REACTION,
		component: SubmitReaction,
		exact: true,
	},
	{
		path: Paths.SUBMIT_REACTION_ERROR,
		component: SubmitReactionError,
	},
	{
		path: Paths.SUBMIT_REACTION_CONFIRMATION,
		component: SubmitReactionConfirmation,
	},
	{
		path: Paths.ONGEDIERTE_MELDEN,
		component: OngedierteMelden,
	},
	{
		path: Paths.ONGEDIERTE_MELDEN_SUCCESS,
		component: OngedierteMeldenSuccess,
	},
	{
		path: Paths.ONGEDIERTE_MELDEN_ERROR,
		component: OngedierteMeldenError,
	},
	{
		path: Paths.CHANGE_IBAN,
		component: ChangeIban,
	},
	{
		path: Paths.CHANGE_IBAN_SUCCESS,
		component: ChangeIbanSuccess,
	},
	{
		path: Paths.CHANGE_IBAN_ERROR,
		component: ChangeIbanError,
	},
	{
		path: Paths.NO_ACCESS,
		component: NoAccess,
		exact: true,
	},
	{
		path: Paths.ZORG_OM_JE_BUUR,
		component: ZorgOmJeBuur,
	},
	{
		path: Paths.ZORG_OM_JE_BUUR_SUCCESS,
		component: ZorgOmJeBuurConfirmation,
	},
	{
		path: Paths.ZORG_OM_JE_BUUR_ERROR,
		component: ZorgOmJeBuurError,
	},
	{
		path: Paths.NOT_FOUND,
		component: NotFound,
	},
];

export const noTemplateRoutes = [
	{
		path: Paths.DOCUMENT_VIEW,
		component: DocumentView,
	},
];

const PublicRoutes = () => {
	return (
		<CenterBoxedContainer>
			<AuthPageTransition>
				{centerBoxedPublicRoutes.map((route) => (
					<Route
						key={route.path}
						path={route.path}
						component={route.component}
						exact={route.exact}
					/>
				))}
			</AuthPageTransition>
		</CenterBoxedContainer>
	);
};

const LoginPublicRoutes = () => {
	return (
		<AuthPageTransition>
			{loginPublicRoutes.map((route) => (
				<Route
					key={route.path}
					path={route.path}
					component={route.component}
				/>
			))}
		</AuthPageTransition>
	);
};

const PrivateRoutes = () => {
	const featureFlagsPresent = useFeatureFlagsPresent();
	const contractor = useUserIsContractor();

	return (
		<PageRoutesContainer>
			{privateRoutesAll.map(({ path, component, featureflag }) => (
				<Route
					key={`private-route-${path}`}
					path={path}
					component={
						featureflag && featureFlagsPresent.includes(featureflag?.featureName)
							? featureflag?.component : component
					}
					exact
				/>
			))}
			{nonContractorFullPageRoutes.map(({ path, component, exact }) => (
				<Route
					key={`non-contractor-${path}`}
					path={path}
					component={component}
					exact={exact}
				/>
			))}
			<Switch>
				({contractorFullPageRoutes.map(({ path, component, exact }) => (
					<Route
						key={`contractor-${path}`}
						path={path}
						component={contractor ? component : NoAccess}
						exact={exact}
					/>
				))})
			</Switch>
		</PageRoutesContainer>
	);
};

const SignupRoutes = () => {
	return (
		<CenterBoxedContainer showBox={false}>
			{signupRoutes.map((route) => (
				<Route
					key={route.toString()}
					path={route.path}
					component={route.component}
					exact={route.exact}
				/>
			))}
		</CenterBoxedContainer>
	);
};

const publicRoutesPaths = centerBoxedPublicRoutes.map(({ path }) => path);
const loginPublicRoutesPaths = loginPublicRoutes.map(({ path }) => path);
const aliasPublicRoutesPaths = aliasPublicRoutes.map(({ path }) => path);
export const allPublicPaths = [...publicRoutesPaths, ...loginPublicRoutesPaths, ...aliasPublicRoutesPaths];
const allRoutes = [...privateRoutesAll, ...nonContractorFullPageRoutes, ...contractorFullPageRoutes].map(({ path }) => path).flat();
export const signupPaths = signupRoutes.map(({ path }) => path).flat();

const AppRoutes = () => {
	useMultiCustomerRouting();
	useNewCustomerRouting();
	const { hasCallback } = useRedirectCallback();
	useScrollRestoration();
	useGoogleTagManagerVirtualPageView();
	useAliasRouting(aliasPublicRoutes);

	const location = useLocation();

	// This prevents the page from rendering when the user is redirected after login
	// When page is external - a flash of the 404 page is shown if this check is not here
	if (hasCallback) {
		return null;
	}

	const knownPaths = [...allRoutes, ...allPublicPaths, ...signupPaths];

	const isSubmitRoute = location.pathname.startsWith('/reageren/');
	const routeArray = location.pathname.split('/');
	// Don't show not found for submit page when: route path is /reageren and /:id (length 3) or is /reageren route with /:id and succes/mislukt page
	const isSubmitRouteAllowed = isSubmitRoute && routeArray.length === 3 || routeArray.length === 4 && routeArray[3] === 'succes' || routeArray[3] === 'mislukt';

	return (
		<LanguageProvider>
			<Switch>
				{noTemplateRoutes.map((route) => (
					<Route
						key={route.path}
						path={route.path}
						component={route.component}
						exact
					/>
				))}
				{/* Checking <Route path='*'>... doesn't work properly so we check if the current path is one of the known paths, if not show not found  */}
				{knownPaths.includes(location.pathname) === false && !isSubmitRouteAllowed && <PageRoutesContainer> <Route component={NotFound} /></PageRoutesContainer>}
				<Route
					path={publicRoutesPaths}
					component={PublicRoutes}
					exact
				/>
				<Route
					path={loginPublicRoutesPaths}
					component={LoginPublicRoutes}
					exact
				/>
				<Route
					path={signupPaths}
					component={SignupRoutes}
				/>
				<Route
					path={allRoutes}
					component={PrivateRoutes}
				/>
			</Switch>
		</LanguageProvider >
	);
};

const b2cPaths = b2cPages.map(({ path }) => path);

const Routing = () => {
	const isOnB2CLogin = useRouteMatch(b2cPaths)?.isExact;

	return (
		<Switch>
			{!isOnB2CLogin && (
				<AppRoutes />
			)}
		</Switch>
	);
};


export default Routing;
