import '../node_modules/@woonstad/design-system/tailwind-stadswonen.css';
import googleTagManager from '@analytics/google-tag-manager';
import { Analytics } from 'analytics';
import { AnalyticsProvider } from 'use-analytics';
import { BrowserRouter } from 'react-router-dom';
import 'translations/i18n';
import { initializeDutchLocale } from 'utils/numeral';
import App from './App';
import './assets/styles/index.scss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom';
import { AuthError, AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, MsalProviderProps } from '@azure/msal-react';
import { msalConfig } from 'auth/config';
import { safeEnv } from 'utils/safeEnv';
import setupDayjs from 'setupDayjs';
import safeWindow from 'utils/safeWindow';
import { b2cPages } from 'Routing';
import { CookiesProvider } from 'react-cookie';
import { Paths } from 'constants/routes';
import { openAPIMutate, openAPISet } from 'utils/openAPI';
import { onRedirectActions } from 'utils/onRedirectActions';
import { isInIFrame } from 'utils/isInIFrame';
import Cookies from 'universal-cookie'; // Peer dep of react-cookie
import getDomain from '../../Shared.Frontend/utils/getDomain';

// Make sure dayjs is setup before the app is rendered
setupDayjs();

safeWindow.env = safeWindow.env || {};

openAPISet('BASE', safeEnv('GATEWAY_BASE_URL') ?? '');

openAPIMutate('HEADERS', (currentHeaders) => ({  ...currentHeaders,
	'X-Brand': 'Stadswonen',
}));

const analytics = Analytics({
	app: 'mijn-stadswonenrotterdam',
	plugins: [
		// https://getanalytics.io/plugins/google-tag-manager/
		googleTagManager({
			containerId: safeWindow.location?.href.includes('localhost') ? '' : 'GTM-NJK9H7',
		}),
	],
});

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
});

const msalInstance = new PublicClientApplication(msalConfig());
onRedirectActions(msalInstance);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
	msalInstance.setActiveAccount(accounts[0]);
}


msalInstance.addEventCallback((event: EventMessage) => {
	if (event.eventType === EventType.LOGIN_FAILURE) {
		const loginFailedURL = new URL(Paths.LOGIN_FAILED, window.location?.href);

		if (event?.error instanceof AuthError) {
			loginFailedURL.searchParams.set('errorMessage', JSON.stringify(event?.error?.errorMessage));
		}

		window.location.href = loginFailedURL.toString();
		return;
	}

	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
		// Remove the customer-id cookie to make sure the user has to choose account again upon next login
		const cookies = new Cookies();
		cookies.remove('customer-id', {
			path: '/',
			domain: `.${getDomain(location.host)}`,
		});

		const payload = event.payload as AuthenticationResult;
		const account = payload.account;
		msalInstance.setActiveAccount(account);
	}
});

initializeDutchLocale();

const rootElement = document.getElementById('root');
const b2cPaths = b2cPages.map((page) => page.path);

const ConditionalB2CWrapper = ({ instance, children }: MsalProviderProps) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const isOnB2CPage = b2cPaths.includes(safeWindow.location?.pathname as any);

	return (
		<CookiesProvider>
			<QueryClientProvider client={queryClient}>
				{isOnB2CPage ? (
					<>
						{children}
					</>
				) : (
					<MsalProvider instance={instance}>
						{children}
					</MsalProvider>
				)}
			</QueryClientProvider>
		</CookiesProvider>
	);
};

// For info on the iframe check:
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1156#issuecomment-565238499

msalInstance.initialize().then(() => {
	ReactDOM.render(
		isInIFrame ? (
			<div />
		) : (
			<ConditionalB2CWrapper instance={msalInstance}>
				<AnalyticsProvider instance={analytics}>
					<BrowserRouter>
						<App pca={msalInstance} />
					</BrowserRouter>
				</AnalyticsProvider>
			</ConditionalB2CWrapper>),
		rootElement,
	);
});
