import { useQuery } from '@tanstack/react-query';
import { AvailabilityService } from '../../../Shared.Frontend/generated-availability/services/AvailabilityService';
import { Flow } from '../../../Shared.Frontend/generated-availability/models/Flow';
import { useContractToUse } from './useContractToUse';

/**
 * Used for checking if a flow is available for the active contract
 */
export const useAvailability = (flow: Flow) => {
	const { contractToUse } = useContractToUse();

	const {
		data,
	} = useQuery({
		queryKey: [`availability-${contractToUse?.id}-${flow}`],
		queryFn: () => AvailabilityService.get(contractToUse?.id, 'stadswonen', flow),
	});

	const selectedFlow = data?.find((flowItem) => flowItem.name === flow);

	return selectedFlow?.available;
};
