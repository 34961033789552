/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Brand } from '../models/Brand';
import type { Flow } from '../models/Flow';
import type { FlowAvailability } from '../models/FlowAvailability';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AvailabilityService {

    /**
     * @param contractId
     * @param brand
     * @param flow
     * @returns FlowAvailability OK
     * @throws ApiError
     */
    public static get(
        contractId?: number,
        brand?: Brand,
        flow?: Flow,
    ): CancelablePromise<Array<FlowAvailability>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/availability/api/availability',
            query: {
                'contractId': contractId,
                'brand': brand,
                'flow': flow,
            },
            errors: {
                403: `Forbidden`,
            },
        });
    }

}
