import { OpenAPIConfig } from '../../../Shared.Frontend/generated-portal-stadswonen/core/OpenAPI';
import { OpenAPI as OpenAPIPortal } from '../../../Shared.Frontend/generated-portal/core/OpenAPI';
import { OpenAPI as OpenAPIPortalBE } from '../../../Shared.Frontend/generated-portal-stadswonen/core/OpenAPI';
import { OpenAPI as OpenAPIContentDelivery } from '@woonstad/api-client-content-delivery';
import { OpenAPI as OpenAPIContentDeliveryWRF } from '../../../Shared.Frontend/generated-content-delivery-wrf/core/OpenAPI';
import { OpenAPI as OpenAPITrackAndTrace } from '../../../Shared.Frontend/generated-track-and-trace/core/OpenAPI';
import { OpenAPI as OpenAPIMaintenance } from '../../../Shared.Frontend/generated-maintenance/core/OpenAPI';
import { OpenAPI as OpenAPIAvailability } from '../../../Shared.Frontend/generated-availability/core/OpenAPI';
import { OpenAPI as OpenAPIAccount } from '../../../Shared.Frontend/generated-account/core/OpenAPI';
import { OpenAPI as OpenAPIPayment } from '../../../Shared.Frontend/generated-payment/core/OpenAPI';
import { OpenAPI as OpenAPIPicobello } from '@woonstad/api-client-picobello';
import { OpenAPI as OpenAPIWebcontracten } from '@woonstad/api-client-web-contracten';

const defaultTrackAndTracePathname = new URL(OpenAPITrackAndTrace.BASE).pathname;
const defaultWebcontractenPathname = new URL(OpenAPIWebcontracten.BASE).pathname;

export const openAPISet = <Property extends keyof OpenAPIConfig>(
	property: Property,
	value: OpenAPIConfig[Property],
) => {
	// Gateway
	OpenAPIPortal[property] = value;
	OpenAPIPortalBE[property] = value;
	OpenAPIContentDelivery[property] = value;
	OpenAPIContentDeliveryWRF[property] = value;
	OpenAPIMaintenance[property] = value;
	OpenAPIAvailability[property] = value;
	OpenAPIAccount[property] = value;
	OpenAPIPayment[property] = value;
	OpenAPIPicobello[property] = value;

	// // Okapi
	// if (property === 'BASE' && value.okapi && typeof value.okapi === 'string') {
	// 	const safeOkapiBase = new URL(value.okapi).origin;
	// 	OpenAPITrackAndTrace.BASE = `${safeOkapiBase}${defaultTrackAndTracePathname}`;
	// 	OpenAPIWebcontracten.BASE = `${safeOkapiBase}${defaultWebcontractenPathname}`;
	// } else {
	// 	OpenAPITrackAndTrace[property] = value.okapi;
	// 	OpenAPIWebcontracten[property] = value.okapi;
	// }
};

export const openAPIMutate = <Property extends keyof OpenAPIConfig>(
	property: Property,
	mutator: (value: OpenAPIConfig[Property]) => OpenAPIConfig[Property],
) => {
	OpenAPIPortal[property] = mutator(OpenAPIPortal[property]);
	OpenAPIPortalBE[property] = mutator(OpenAPIPortalBE[property]);
	OpenAPIContentDeliveryWRF[property] = mutator(OpenAPIContentDeliveryWRF[property]);
	OpenAPIMaintenance[property] = mutator(OpenAPIMaintenance[property]);
	OpenAPIAvailability[property] = mutator(OpenAPIAvailability[property]);
	OpenAPIAccount[property] = mutator(OpenAPIAvailability[property]);
	OpenAPIPayment[property] = mutator(OpenAPIPayment[property]);
};
