import Layout from 'components/layout/Layout';
import StatusList from 'components/status-list/StatusList';
import Typography from 'components/typography/Typography';
import { useGoogleTagManagerTrackEvent } from 'hooks/app/useGoogleTagManager';
import { FunctionComponent, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import RequestKeyForm from './request-key-form/RequestKeyForm';
import styles from './RequestKey.module.scss';
import SkeletonSwitch from 'components/skeleton-switch/SkeletonSwitch';
import RequestKeySkeleton from './request-key-skeleton/RequestKeySkeleton';
import { useRequestKeyPrices } from 'hooks/store/useRequestKeyPrices';
import { useAvailability } from 'hooks/useAvailability';
import { useConditionalRedirect } from 'hooks/useConditionalRedirect';
import { Paths } from 'constants/routes';

/**
 * In Stadswonen key payments are handles outside of the portal. For Woonstad people do pay via the site.
 */
const RequestKey: FunctionComponent = () => {
	const isAvailable = useAvailability('requestKey');
	useConditionalRedirect(!isAvailable, Paths.NO_ACCESS);

	useGoogleTagManagerTrackEvent('event', 'Goal', 'request-key-form', 'Form start');
	const { prices, success } = useRequestKeyPrices();
	const { t } = useTranslation();

	const states = useMemo(
		() => [
			{
				title: t(
					'Kies welke sleutel je extra wilt aanvragen.',
				),
			},
			{
				title: t(
					'Je betaalt de sleutel direct bij aanvragen.',
				),
			},
			{
				title: t(
					'De levertijd van de sleutel is maximaal vier weken.',
				),
			},
			{
				title: t(
					'Wanneer wij de sleutel hebben gekregen sturen we je een bericht. Je kunt de sleutel ophalen aan de balie van Stadswonen.',
				),
			},
		],
		[t],
	);

	return (
		<Layout
			title={t('Sleutel aanvragen')}
			id='request-key-page'
		>
			<div className={'grid-1-lg'}>
				<Typography variant='body'>
					<Trans>
						Sleutels van bijvoorbeeld je eigen voordeur laat je eenvoudig zelf bijmaken. Veel sleutels van algemene
						portiek- of algemene bergingsdeuren maken deel uit van een sluitplan.
					</Trans>
				</Typography>
				<div className={styles.listContainer}>
					<Typography
						variant='body'
						fontWeight='700'
						color='purple-300'
					>
						<Trans>Hoe werkt het?</Trans>
					</Typography>
					<StatusList
						states={states}
						darkText
					/>
				</div>
				<SkeletonSwitch
					showContent={success === true}
					skeleton={<RequestKeySkeleton />}
				>
					<RequestKeyForm
						data={prices}
						isSuccess={success}
					/>
				</SkeletonSwitch>
			</div>
		</Layout>
	);
};

export default RequestKey;
