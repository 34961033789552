import { FunctionComponent } from 'react';
import './RequestKeySkeleton.scss';
import Box from 'components/box/Box';
import SkeletonGradient from 'components/skeleton/SkeletonGradient';

const RequestKeySkeleton: FunctionComponent = () => {
	return (
		<Box style={{ width: '100%' }}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				className='recent-payments-skeleton'
			>
				<defs>
					<SkeletonGradient
						id='requestKeyDark'
						color='dark'
					/>
				</defs>

				<clipPath id='requestKeyDarkMask'>
					<rect
						x='0%'
						y='0'
						width='42%'
						height='24'
					/>
					<rect
						x='0%'
						y='54'
						width='40%'
						height='20'
					/>
					<rect
						x='0%'
						y='88'
						width='42%'
						height='132'
					/>
					<rect
						x='0%'
						y='240'
						width='40%'
						height='20'
					/>
					<rect
						x='0%'
						y='270'
						width='46%'
						height='46'
					/>
				</clipPath>
				<rect
					width='100%'
					height='100%'
					clipPath='url(#requestKeyDarkMask)'
					fill='url(#requestKeyDark)'
				/>
			</svg>
		</Box>
	);
};

export default RequestKeySkeleton;
