import clsx from 'clsx';
import Close from 'assets/svg/close.svg?react';
import Typography from 'components/typography/Typography';
import { FunctionComponent } from 'react';
import './StatusList.scss';

export enum StatusType {
	DONE = 'done',
	PENDING = 'pending',
	ERROR = 'error'
}

export interface StatusListEntry {
	title: string;
	type?: StatusType;
}

interface Props {
	className?: string;
	states: StatusListEntry[];
	darkText?: boolean;
	largeGap?: boolean;
}

const StatusList: FunctionComponent<Props> = ({ states, className, darkText = false, largeGap = false }) => {
	const classes = clsx('status-list', className, {
		'status-list--dark-text': darkText,
		'status-list--large-gap': largeGap,
	});
	return (
		<ul className={classes}>
			{states.map((status, index) => {
				const type = status.type || StatusType.DONE;
				const itemClasses = clsx('status-list__item', `status-list__item--${type}`, {
					'status-list__item--preceeds-pending':
						index !== states.length - 1 && states[index + 1].type === StatusType.PENDING,
				});
				return (
					<li
						className={itemClasses}
						key={index}
					>
						{type === StatusType.ERROR && <Close className='status-list__icon-error' />}
						{type !== StatusType.ERROR && <span className='status-list__icon' />}
						<Typography
							type='p'
							variant='body-small'
							className='status-list__item-title'
							color={darkText ? 'purple-300' : 'purple-200'}
							dangerouslySetInnerHTML={status.title}
						/>
					</li>
				);
			})}
		</ul>
	);
};

export default StatusList;
