import i18next from 'i18next';

export const ENGLISH_TO_WSR_STORAGE_KEY = 'WS_SHOW_ENGLISH_TO_WSR_NOTIFICATION';
export const ENGLISH_TO_WSR_STORAGE_VALUE = 'dont-show-message-english-portal-to-woonstadrotterdam';
export const LANGUAGE_STORAGE_KEY = 'WS_LANG';
export const LOCAL_STORAGE_KEY = 'WS_TOKEN';
export const IDENTITY_NAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';
export const IDENTITY_ROLE = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
export const REFRESH_AUTH_TIME: number = 1000 * 60 * 5;
export const RETRY_TIMEOUT = 5000;
export const TWO_DECIMALS_FORMAT = '0.0,';

export enum ContractType {
	HOME = 'home',
	PARKING = 'parking'
}

export const countryOptions = () => [
	{ value: 'NL', label: i18next.t('Nederland') },
	{ value: 'AF', label: i18next.t('Afghanistan') },
	{ value: 'AL', label: i18next.t('Albanië') },
	{ value: 'DZ', label: i18next.t('Algerije') },
	{ value: 'VI', label: i18next.t('Amerikaanse Maagdeneilanden') },
	{ value: 'AS', label: i18next.t('Amerikaans-Samoa') },
	{ value: 'AD', label: i18next.t('Andorra') },
	{ value: 'AO', label: i18next.t('Angola') },
	{ value: 'AI', label: i18next.t('Anguilla') },
	{ value: 'AG', label: i18next.t('Antigua en Barbuda') },
	{ value: 'AR', label: i18next.t('Argentinie') },
	{ value: 'AM', label: i18next.t('Armenië') },
	{ value: 'AW', label: i18next.t('Aruba') },
	{ value: 'AU', label: i18next.t('Australië') },
	{ value: 'AZ', label: i18next.t('Azerbeidzjan') },
	{ value: 'BS', label: i18next.t('Bahama\'s') },
	{ value: 'BH', label: i18next.t('Bahrein') },
	{ value: 'BD', label: i18next.t('Bangladesh') },
	{ value: 'BB', label: i18next.t('Barbados') },
	{ value: 'BE', label: i18next.t('België') },
	{ value: 'BZ', label: i18next.t('Belize') },
	{ value: 'BJ', label: i18next.t('Benin') },
	{ value: 'BM', label: i18next.t('Bermuda') },
	{ value: 'BT', label: i18next.t('Bhutan') },
	{ value: 'BO', label: i18next.t('Bolivia') },
	{ value: 'BQ', label: i18next.t('Bonaire, Sint Eustatius en Saba') },
	{ value: 'BA', label: i18next.t('Bosnië en Herzegovina') },
	{ value: 'BW', label: i18next.t('Botswana') },
	{ value: 'BV', label: i18next.t('Bouvet eiland') },
	{ value: 'BR', label: i18next.t('Brazilië') },
	{ value: 'VG', label: i18next.t('Britse Maagdeneilanden') },
	{ value: 'BN', label: i18next.t('Brunei') },
	{ value: 'BG', label: i18next.t('Bulgarije') },
	{ value: 'BF', label: i18next.t('Burkina Faso') },
	{ value: 'BI', label: i18next.t('Burundi') },
	{ value: 'KH', label: i18next.t('Cambodja') },
	{ value: 'CA', label: i18next.t('Canada') },
	{ value: 'KY', label: i18next.t('Caymaneilanden') },
	{ value: 'CF', label: i18next.t('Centraal-Afrikaanse Republiek') },
	{ value: 'CL', label: i18next.t('Chili') },
	{ value: 'CN', label: i18next.t('China') },
	{ value: 'CX', label: i18next.t('Christmaseiland') },
	{ value: 'CC', label: i18next.t('Cocos eilanden') },
	{ value: 'CO', label: i18next.t('Colombia') },
	{ value: 'KM', label: i18next.t('Comoren') },
	{ value: 'CG', label: i18next.t('Congo') },
	{ value: 'CD', label: i18next.t('Congo, Dem. Rep.') },
	{ value: 'CK', label: i18next.t('Cookeilanden') },
	{ value: 'CR', label: i18next.t('Costa Rica') },
	{ value: 'CU', label: i18next.t('Cuba') },
	{ value: 'CW', label: i18next.t('Curaçao') },
	{ value: 'CY', label: i18next.t('Cyprus') },
	{ value: 'DK', label: i18next.t('Denemarken') },
	{ value: 'DJ', label: i18next.t('Djibouti') },
	{ value: 'DM', label: i18next.t('Dominica') },
	{ value: 'DO', label: i18next.t('Dominicaanse Republiek') },
	{ value: 'DE', label: i18next.t('Duitsland') },
	{ value: 'EC', label: i18next.t('Ecuador') },
	{ value: 'EG', label: i18next.t('Egypte') },
	{ value: 'SV', label: i18next.t('El Salvador') },
	{ value: 'GQ', label: i18next.t('Equatoriaal-Guinea') },
	{ value: 'ER', label: i18next.t('Eritrea') },
	{ value: 'EE', label: i18next.t('Estland') },
	{ value: 'ET', label: i18next.t('Ethiopië') },
	{ value: 'FO', label: i18next.t('Faeröer') },
	{ value: 'FK', label: i18next.t('Falklandeilanden') },
	{ value: 'FJ', label: i18next.t('Fiji') },
	{ value: 'PH', label: i18next.t('Filipijnen') },
	{ value: 'FI', label: i18next.t('Finland') },
	{ value: 'FR', label: i18next.t('Frankrijk') },
	{ value: 'TF', label: i18next.t('Frankrijk zuidelijke rijksdelen') },
	{ value: 'GF', label: i18next.t('Frans-Guyana') },
	{ value: 'PF', label: i18next.t('Frans-Polynesië') },
	{ value: 'GA', label: i18next.t('Gabon') },
	{ value: 'GM', label: i18next.t('Gambia') },
	{ value: 'GE', label: i18next.t('Georgië') },
	{ value: 'GH', label: i18next.t('Ghana') },
	{ value: 'GI', label: i18next.t('Gibraltar') },
	{ value: 'GD', label: i18next.t('Grenada') },
	{ value: 'GR', label: i18next.t('Griekenland') },
	{ value: 'GL', label: i18next.t('Groenland') },
	{ value: 'GP', label: i18next.t('Guadeloupe') },
	{ value: 'GU', label: i18next.t('Guam') },
	{ value: 'GT', label: i18next.t('Guatemala') },
	{ value: 'GN', label: i18next.t('Guinee') },
	{ value: 'GW', label: i18next.t('Guinee-Bissau') },
	{ value: 'GY', label: i18next.t('Guyana') },
	{ value: 'HT', label: i18next.t('Haïti') },
	{ value: 'HM', label: i18next.t('Heard en McDonald eilanden') },
	{ value: 'HN', label: i18next.t('Honduras') },
	{ value: 'HU', label: i18next.t('Hongarije') },
	{ value: 'HK', label: i18next.t('Hongkong') },
	{ value: 'IE', label: i18next.t('Ierland') },
	{ value: 'IS', label: i18next.t('IJsland') },
	{ value: 'IN', label: i18next.t('India') },
	{ value: 'ID', label: i18next.t('Indonesië') },
	{ value: 'IQ', label: i18next.t('Irak') },
	{ value: 'IR', label: i18next.t('Iran') },
	{ value: 'IL', label: i18next.t('Israël') },
	{ value: 'IT', label: i18next.t('Italië') },
	{ value: 'CI', label: i18next.t('Ivoorkust') },
	{ value: 'JM', label: i18next.t('Jamaica') },
	{ value: 'JP', label: i18next.t('Japan') },
	{ value: 'YE', label: i18next.t('Jemen') },
	{ value: 'JO', label: i18next.t('Jordanië') },
	{ value: 'CV', label: i18next.t('Kaapverdië') },
	{ value: 'CM', label: i18next.t('Kameroen') },
	{ value: 'KZ', label: i18next.t('Kazachstan') },
	{ value: 'KE', label: i18next.t('Kenia') },
	{ value: 'KI', label: i18next.t('Kiribati') },
	{ value: 'KW', label: i18next.t('Koeweit') },
	{ value: 'XK', label: i18next.t('Kosovo') },
	{ value: 'Kr', label: i18next.t('Kroatië') },
	{ value: 'HR', label: i18next.t('Kroatië') },
	{ value: 'KG', label: i18next.t('Kyrgyzstan') },
	{ value: 'LA', label: i18next.t('Laos') },
	{ value: 'LS', label: i18next.t('Lesotho') },
	{ value: 'LV', label: i18next.t('Letland') },
	{ value: 'LB', label: i18next.t('Libanon') },
	{ value: 'LR', label: i18next.t('Liberia') },
	{ value: 'LY', label: i18next.t('Libië') },
	{ value: 'LI', label: i18next.t('Liechtenstein') },
	{ value: 'LT', label: i18next.t('Litouwen') },
	{ value: 'LU', label: i18next.t('Luxemburg') },
	{ value: 'MO', label: i18next.t('Macau') },
	{ value: 'MK', label: i18next.t('Macedonië') },
	{ value: 'MG', label: i18next.t('Madagaskar') },
	{ value: 'MW', label: i18next.t('Malawi') },
	{ value: 'MV', label: i18next.t('Maldiven') },
	{ value: 'MY', label: i18next.t('Maleisië') },
	{ value: 'ML', label: i18next.t('Mali') },
	{ value: 'MT', label: i18next.t('Malta') },
	{ value: 'MA', label: i18next.t('Marokko') },
	{ value: 'MH', label: i18next.t('Marshalleilanden') },
	{ value: 'MQ', label: i18next.t('Martinique') },
	{ value: 'MR', label: i18next.t('Mauritanië') },
	{ value: 'MU', label: i18next.t('Mauritius') },
	{ value: 'YT', label: i18next.t('Mayotte') },
	{ value: 'MX', label: i18next.t('Mexico') },
	{ value: 'FM', label: i18next.t('Micronesia') },
	{ value: 'MD', label: i18next.t('Moldavië') },
	{ value: 'MC', label: i18next.t('Monaco') },
	{ value: 'MN', label: i18next.t('Mongolië') },
	{ value: 'ME', label: i18next.t('Montenegro') },
	{ value: 'MS', label: i18next.t('Montserrat') },
	{ value: 'MZ', label: i18next.t('Mozambique') },
	{ value: 'MM', label: i18next.t('Myanmar') },
	{ value: 'NA', label: i18next.t('Namibie') },
	{ value: 'NR', label: i18next.t('Nauru') },
	{ value: 'AN', label: i18next.t('Nederlandse Antillen') },
	{ value: 'NP', label: i18next.t('Nepal') },
	{ value: 'NI', label: i18next.t('Nicaragua') },
	{ value: 'NC', label: i18next.t('Nieuw-Caledonië') },
	{ value: 'NZ', label: i18next.t('Nieuw-Zeeland') },
	{ value: 'NE', label: i18next.t('Niger') },
	{ value: 'NG', label: i18next.t('Nigeria') },
	{ value: 'NU', label: i18next.t('Niue') },
	{ value: 'MP', label: i18next.t('Noordelijke Marianen') },
	{ value: 'KP', label: i18next.t('Noord-Korea') },
	{ value: 'NO', label: i18next.t('Noorwegen') },
	{ value: 'NF', label: i18next.t('Norfolk') },
	{ value: 'UA', label: i18next.t('Oekraïne') },
	{ value: 'UZ', label: i18next.t('Oezbekistan') },
	{ value: 'OM', label: i18next.t('Oman') },
	{ value: 'XX', label: i18next.t('Onbekend') },
	{ value: 'AT', label: i18next.t('Oostenrijk') },
	{ value: 'TL', label: i18next.t('Oost-Timor') },
	{ value: 'PK', label: i18next.t('Pakistan') },
	{ value: 'PW', label: i18next.t('Palau') },
	{ value: 'PS', label: i18next.t('Palestina') },
	{ value: 'PA', label: i18next.t('Panama') },
	{ value: 'PG', label: i18next.t('Papoea-Nieuw-Guinea') },
	{ value: 'PY', label: i18next.t('Paraguay') },
	{ value: 'PE', label: i18next.t('Peru') },
	{ value: 'PN', label: i18next.t('Pitcairneilanden') },
	{ value: 'PL', label: i18next.t('Polen') },
	{ value: 'PT', label: i18next.t('Portugal') },
	{ value: 'PR', label: i18next.t('Puerto Rico') },
	{ value: 'QA', label: i18next.t('Qatar') },
	{ value: 'RE', label: i18next.t('Réunion') },
	{ value: 'RO', label: i18next.t('Roemenië') },
	{ value: 'RU', label: i18next.t('Rusland') },
	{ value: 'RW', label: i18next.t('Rwanda') },
	{ value: 'SH', label: i18next.t('Saint Helena, Ascension en Tristan da Cunha') },
	{ value: 'KN', label: i18next.t('Saint Kitts en Nevis') },
	{ value: 'LC', label: i18next.t('Saint Lucia') },
	{ value: 'PM', label: i18next.t('Saint Pierre en Miquelon') },
	{ value: 'VC', label: i18next.t('Saint Vincent en de Grenadines') },
	{ value: 'SB', label: i18next.t('Salomonseilanden') },
	{ value: 'WS', label: i18next.t('Samoa') },
	{ value: 'SM', label: i18next.t('San Marino') },
	{ value: 'ST', label: i18next.t('Sao Tomé en Principe') },
	{ value: 'SA', label: i18next.t('Saudi-Arabië') },
	{ value: 'SN', label: i18next.t('Senegal') },
	{ value: 'RS', label: i18next.t('Servië') },
	{ value: 'SC', label: i18next.t('Seychellen') },
	{ value: 'SL', label: i18next.t('Sierra Leone') },
	{ value: 'SG', label: i18next.t('Singapore') },
	{ value: 'MF', label: i18next.t('Sint Maarten (Frans)') },
	{ value: 'SX', label: i18next.t('Sint Maarten (Nederlands)') },
	{ value: 'SI', label: i18next.t('Slovenië') },
	{ value: 'SK', label: i18next.t('Slowakije') },
	{ value: 'SO', label: i18next.t('Somalië') },
	{ value: 'ES', label: i18next.t('Spanje') },
	{ value: 'LK', label: i18next.t('Sri Lanka') },
	{ value: 'SD', label: i18next.t('Sudan') },
	{ value: 'SR', label: i18next.t('Suriname') },
	{ value: 'SJ', label: i18next.t('Svalbard en Jan Mayen') },
	{ value: 'SZ', label: i18next.t('Swaziland') },
	{ value: 'SY', label: i18next.t('Syrie') },
	{ value: 'TJ', label: i18next.t('Tadzjikistan') },
	{ value: 'TW', label: i18next.t('Taiwan') },
	{ value: 'TZ', label: i18next.t('Tanzania') },
	{ value: 'TH', label: i18next.t('Thailand') },
	{ value: 'TG', label: i18next.t('Togo') },
	{ value: 'TK', label: i18next.t('Tokelau-eilanden') },
	{ value: 'TO', label: i18next.t('Tonga') },
	{ value: 'TT', label: i18next.t('Trinidad en Tobago') },
	{ value: 'TD', label: i18next.t('Tsjaad') },
	{ value: 'CZ', label: i18next.t('Tsjechië') },
	{ value: 'TN', label: i18next.t('Tunesië') },
	{ value: 'TR', label: i18next.t('Turkije') },
	{ value: 'TM', label: i18next.t('Turkmenistan') },
	{ value: 'TC', label: i18next.t('Turks- en Caicoseilanden') },
	{ value: 'TV', label: i18next.t('Tuvalu') },
	{ value: 'UG', label: i18next.t('Uganda') },
	{ value: 'UY', label: i18next.t('Uruguay') },
	{ value: 'VU', label: i18next.t('Vanuatu') },
	{ value: 'VA', label: i18next.t('Vaticaanstad') },
	{ value: 'VE', label: i18next.t('Venezuela') },
	{ value: 'GB', label: i18next.t('Verenigd Koninkrijk') },
	{ value: 'IO', label: i18next.t('Verenigd Koninkrijk rijksdelen Indische oceaan') },
	{ value: 'AE', label: i18next.t('Verenigde Arabische Emiraten') },
	{ value: 'US', label: i18next.t('Verenigde Staten') },
	{ value: 'UM', label: i18next.t('Verenigde Staten overzeese rijksdelen') },
	{ value: 'VN', label: i18next.t('Vietnam') },
	{ value: 'WF', label: i18next.t('Wallis en Futuna') },
	{ value: 'EH', label: i18next.t('Westelijke Sahara') },
	{ value: 'BY', label: i18next.t('Wit-Rusland') },
	{ value: 'ZM', label: i18next.t('Zambia') },
	{ value: 'ZW', label: i18next.t('Zimbabwe') },
	{ value: 'GS', label: i18next.t('Zuid Georgia en Zuid Sandwich eilanden') },
	{ value: 'ZA', label: i18next.t('Zuid-Afrika') },
	{ value: 'KR', label: i18next.t('Zuid-Korea') },
	{ value: 'SS', label: i18next.t('Zuid-Sudan') },
	{ value: 'SE', label: i18next.t('Zweden') },
	{ value: 'CH', label: i18next.t('Zwitserland') },
];
