import { ContractType } from '../../../../Shared.Frontend/constants/index';
import { FormBlockType } from 'pages/dashboard/types';
import { Paths } from 'constants/routes';
import { paymentMethodName } from 'constants/payments';
import { SwFlowsKlachtOverlastMelden, SwFlowsMijnWoning, SwFlowsHuurEnBetalingen, SwFlowsMijnParkeerplaats, SwFlowsBelangrijkeDocumenten, SwFlowsSleutelAanvragen, SwFlowsReparatieAanvragen, SwFlowsBezwaarMakenHuurverhoging, SwFlowsBetalingsregelingAanvragen, SwFlowsBetaaldatumWijzigen, SwFlowsBetaalmanier, SwFlowsHuurOpzeggen, SwFlowsVerhuurdersverklaringAanvragen, SwFlowsZorgOmJeBuur, SwFlowsVerkeerdGebruik, SwFlowsRekeningnummerWijzigen, SwFlowsMedebewonerUitschrijven, SwFlowsMijnHuishouden, SwFlowsOngedierteMelden } from '@woonstad/design-system';

import { ValueOf } from '../../../../Shared.Frontend/types/utils';
import { TFunction } from 'i18next';
import { getExternalLink } from 'utils/getExternalLink';
type ContractTypeMap = Record<
	ContractType,
	{
		tabs: Array<{
			title: string | ((t: TFunction) => string);
			testid?: string;
			icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
			path: ValueOf<typeof Paths>;
			formBlocksCol1: Array<FormBlockType>;
			formBlocksCol2: Array<FormBlockType>;
		}>;
	}
>

export const DASHBOARD_CONTRACT_TYPE_MAP: ContractTypeMap = {
	[ContractType.HOME]: {
		tabs: [
			{
				title: (t: TFunction) => t('In en om mijn woonruimte'),
				testid: 'woonruimteTab',
				icon: SwFlowsMijnWoning,
				path: Paths.DASHBOARD_RUIMTE,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: (t: TFunction) => t('Reparaties, overlast en zorg'),
						testid: 'reparatiesEnOverlastTitle',
						buttons: [
							{
								title: (t: TFunction) => t('Reparatie aanvragen'),
								testid: 'repairRequestLink',
								icon: SwFlowsReparatieAanvragen,
								link: Paths.MAINTENANCE,
								when: 'always',
							},
							{
								title: (t: TFunction) => t('Overlast melden'),
								testid: 'overlastMeldenLink',
								icon: SwFlowsKlachtOverlastMelden,
								link: Paths.OVERLAST_MELDEN,
								when: 'always',
							},
							{
								title: 'Ongedierte melden',
								testid: 'ongedierteMeldenLink',
								icon: SwFlowsOngedierteMelden,
								link: Paths.ONGEDIERTE_MELDEN,
								when: ({ featureFlagsPresent, flowAvailability }): boolean => {
									return Array.isArray(featureFlagsPresent) && featureFlagsPresent.includes('ongedierte-melden')
									&& (flowAvailability?.find((flow) => flow.name === 'ongedierteMelding')?.available ?? true);
								},
							},
							{
								title: 'Verkeerd gebruik woning melden',
								testid: 'woonfraudeMeldenLink',
								icon: SwFlowsVerkeerdGebruik,
								link: Paths.WOONFRAUDE_MELDEN,
								when: 'always',
							},
							{
								title: (t: TFunction) => t('Zorg om je buur melden'),
								testid: 'zorgOmJeBuurMeldenLink',
								icon: SwFlowsZorgOmJeBuur,
								link: Paths.ZORG_OM_JE_BUUR,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'zorgenOmBuur')?.available ?? true;
								},
							},
						],
					},
				],
				formBlocksCol2: [
					{
						type: 'buttons',
						title: (t: TFunction) => t('Sleutel aanvragen'),
						testid: 'sleutelAanvragenTitle',
						buttons: [
							{
								title: (t: TFunction) => t('Sleutel aanvragen'),
								testid: 'requestKeyLink',
								icon: SwFlowsSleutelAanvragen,
								link: Paths.REQUEST_KEY,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestKey')?.available ?? true;
								},
							},
							// {
							// 	title: 'Sleutel aanvragen (nieuw)',
							// 	testid: 'requestKeyLink',
							// 	icon: SwFlowsSleutelAanvragen,
							// 	link: Paths.SLEUTEL_AANVRAGEN,
							// 	when: ({ flowAvailability, featureFlagsPresent }): boolean => {
							// 		return Array.isArray(featureFlagsPresent) && featureFlagsPresent.includes('sleutel-aanvragen')
							// 			&& (flowAvailability?.find((flow) => flow.name === 'requestKey')?.available ?? true);
							// 	},
							// },
						],
					},
				],
			},
			{
				title: (t: TFunction) => t('Huur en betalingen'),
				testid: 'huurBetalingenTab',
				icon: SwFlowsHuurEnBetalingen,
				path: Paths.DASHBOARD_HUUR_BETALINGEN,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: (t: TFunction) => t('Huur betalen'),
						testid: 'huurBetalenTitle',
						buttons: [
							{
								title: (t: TFunction) => t('Betaalmanier'),
								testid: 'paymentMethodLink',
								subtitle: (contract) => paymentMethodName(contract?.paymentMethod),
								icon: SwFlowsBetaalmanier,
								link: () => undefined,
								when: 'always',
							},
							{
								title: 'Rekeningnummer wijzigen',
								testid: 'changeIbanLink',
								icon: SwFlowsRekeningnummerWijzigen,
								link: () => Paths.CHANGE_IBAN,
								when: 'always',
							},
							{
								title: (t: TFunction) => t('Betaaldatum wijzigen'),
								testid: 'changeDirectDebitDateLink',
								icon: SwFlowsBetaaldatumWijzigen,
								link: Paths.CHANGE_DIRECT_DEBIT_DATE,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'changeDirectDebitDate')?.available ?? true;
								},
							},
							// @TODO we don't have this flow yet
							// {
							// 	// @TODO This availability is not in sync with logic implemented in AdvancementAdjusted.tsx line 120
							// 	title: 'Voorschot stookkosten wijzigen',
							// 	icon: Heat,
							// 	link: (contract) => Paths.ADVANCEMENT_ADJUSTMENT.replace(':contractId', String(contract.id)),
							// 	when: ({ flowAvailability }): boolean => {
							// 		return flowAvailability?.find((flow) => flow.name === 'adjustAdvancement')?.available ?? true;
							// 	},
							// },
							{
								title: (t: TFunction) => t('Betalingsregeling aanvragen'),
								testid: 'requestPaymentPlanLink',
								icon: SwFlowsBetalingsregelingAanvragen,
								link: Paths.PAYMENT_PLAN,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestPaymentPlan')?.available ?? true;
								},
							},
							// @TODO we don't have this flow yet
							// {
							// 	title: 'Huurverlaging aanvragen',
							// 	icon: RentDecrease,
							// 	link: Paths.RENT_REDUCTION_REQUEST,
							// 	when: ({ flowAvailability }): boolean => {
							// 		return flowAvailability?.find((flow) => flow.name === 'requestRentReduction')?.available ?? true;
							// 	},
							// },
							{
								title: (t: TFunction) => t('Bezwaar maken huuraanpassing'),
								testid: 'opposeRentIncreaseLink',
								icon: SwFlowsBezwaarMakenHuurverhoging,
								link: Paths.RENT_INCREASE_OBJECTION,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'opposeRentIncrease')?.available ?? true;
								},
							},
						],
					},
					{
						type: 'buttons',
						title: (t: TFunction) => t('Opzeggen en verhuurdersverklaring'),
						testid: 'opzeggenEnVerhuurdersverklaringTitle',
						buttons: [
							{
								title: (t: TFunction) => t('Huur opzeggen'),
								testid: 'cancelContractLink',
								icon: SwFlowsHuurOpzeggen,
								link: Paths.CANCEL_CONTRACT,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'cancelContract')?.available ?? true;
								},
							},
							{
								title: (t: TFunction) => t('Verhuurdersverklaring aanvragen'),
								testid: 'requestRentalReferenceLetterLink',
								icon: SwFlowsVerhuurdersverklaringAanvragen,
								link: Paths.RENTAL_REFERENCE_LETTER,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestRentalReferenceLetter')?.available ?? true;
								},
							},
						],
					},
				],
				formBlocksCol2: [
					{
						type: 'betalingen',
						when: 'always',
					},
				],
			},
			{
				title: (t: TFunction) => t('Mijn huishouden'),
				testid: 'huishoudenTab',
				icon: SwFlowsMijnHuishouden,
				path: Paths.DASHBOARD_HUISHOUDEN,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: (t: TFunction) => t('Wijziging doorgeven'),
						testid: 'wijzigingDoorgevenTitle',
						buttons: [
							{
								title: (t: TFunction) => t('Een inwoner vertrekt'),
								subtitle: 'Deze persoon woont bij jou, maar is niet verantwoordelijk voor de huur.',
								testid: 'inwonerVertrektLink',
								icon: SwFlowsMedebewonerUitschrijven,
								link: Paths.INWONER_VERTREKT,
								when: ({ featureFlagsPresent }): boolean => {
									// TODO: Add availability check when BE is ready, remove featureFlagsPresent check
									return Array.isArray(featureFlagsPresent) && featureFlagsPresent.includes('inwoner-vertrekt');
									// && (flowAvailability?.find((flow) => flow.name === 'inwonerVertrekt')?.available ?? true);
								},
							},
						],
					},
				],
				formBlocksCol2: [
					{
						type: 'bewoners',
						when: 'always',
					},
				],
			},
			{
				title: (t: TFunction) => t('Belangrijke documenten'),
				testid: 'documentenTab',
				icon: SwFlowsBelangrijkeDocumenten,
				path: Paths.DASHBOARD_DOCUMENTEN,
				formBlocksCol1: [
					{
						type: 'documenten',
						when: 'always',
					},
				],
				formBlocksCol2: [],
			},
		],
	},
	[ContractType.PARKING]: {
		tabs: [
			{
				title: (t: TFunction) => t('In en om mijn parkeerplaats'),
				testid: 'parkeerplaatsTab',
				icon: SwFlowsMijnParkeerplaats,
				path: Paths.DASHBOARD_PARKEERPLAATS,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: (t: TFunction) => t('Overlast'),
						testid: 'overlastTitle',
						buttons: [
							{
								title: (t: TFunction) => t('Overlast melden'),
								testid: 'overlastMeldenLink',
								icon: SwFlowsKlachtOverlastMelden,
								link: getExternalLink('COMPLAINT_OR_ANNOYANCE'),
								when: 'always',
							},
						],
					},
				],
				formBlocksCol2: [],
			},
			{
				title: (t: TFunction) => t('Huur & betalingen'),
				testid: 'huurBetalingenTab',
				icon: SwFlowsHuurEnBetalingen,
				path: Paths.DASHBOARD_HUUR_BETALINGEN,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: (t: TFunction) => t('Huur betalen'),
						testid: 'huurBetalenTitle',
						buttons: [
							{
								title: 'Rekeningnummer wijzigen',
								testid: 'changeIbanLink',
								icon: SwFlowsRekeningnummerWijzigen,
								link: () => Paths.CHANGE_IBAN,
								when: 'always',
							},
							{
								title: (t: TFunction) => t('Betaaldatum wijzigen'),
								testid: 'changeDirectDebitDateLink',
								icon: SwFlowsBetaaldatumWijzigen,
								link: Paths.CHANGE_DIRECT_DEBIT_DATE,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'changeDirectDebitDate')?.available ?? true;
								},
							},
							{
								title: (t: TFunction) => t('Betalingsregeling aanvragen'),
								testid: 'requestPaymentPlanLink',
								icon: SwFlowsBetalingsregelingAanvragen,
								link: Paths.PAYMENT_PLAN,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestPaymentPlan')?.available ?? true;
								},
							},
						],
					},
					{
						type: 'buttons',
						title: (t: TFunction) => t('Opzeggen'),
						testid: 'opzeggenTitle',
						buttons: [
							{
								title: (t: TFunction) => t('Huur opzeggen'),
								testid: 'cancelContractLink',
								icon: SwFlowsHuurOpzeggen,
								link: Paths.CANCEL_CONTRACT,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'cancelContract')?.available ?? true;
								},
							},
						],
					},
				],
				formBlocksCol2: [
					{
						type: 'betalingen',
						when: 'always',
					},
				],
			},
			{
				title: (t: TFunction) => t('Belangrijke documenten'),
				testid: 'documentenTab',
				icon: SwFlowsBelangrijkeDocumenten,
				path: Paths.DASHBOARD_DOCUMENTEN,
				formBlocksCol1: [
					{
						type: 'documenten',
						when: 'always',
					},
				],
				formBlocksCol2: [],
			},
		],
	},
	[ContractType.BUSINESS]: {
		tabs: [],
		// 		{
		// 			title: 'In en om mijn bedrijfsruimte',
		// 			icon: Business,
		// 			path: Paths.DASHBOARD_RUIMTE,
		// 			formBlocksCol1: [
		// 				{
		// 					type: 'buttons',
		// 					title: 'Reparaties',
		// 					buttons: [
		// 						{
		// 							title: 'Reparatie aanvragen',
		// 							icon: Repair,
		// 							link: Paths.MAINTENANCE,
		// 							when: 'always',
		// 						},
		// 					],
		// 				},
		// 			],
		// 			formBlocksCol2: [],
		// 		},
		// 		{
		// 			title: 'Huur & betalingen',
		// 			icon: Money,
		// 			path: Paths.DASHBOARD_HUUR_BETALINGEN,
		// 			formBlocksCol1: [
		// 				{
		// 					type: 'buttons',
		// 					title: 'Opzeggen',
		// 					buttons: [
		// 						{
		// 							title: 'Huur opzeggen',
		// 							icon: CancelContract,
		// 							link: Paths.CANCEL_CONTRACT,
		// 							when: ({ flowAvailability }): boolean => {
		// 								return flowAvailability?.find((flow) => flow.name === 'cancelContract')?.available ?? true;
		// 							},
		// 						},
		// 					],
		// 				},
		// 			],
		// 			formBlocksCol2: [
		// 				{
		// 					type: 'betalingen',
		// 					when: 'always',
		// 				},
		// 			],
		// 		},
		// 		{
		// 			title: 'Facturen',
		// 			icon: Invoices,
		// 			path: Paths.DASHBOARD_FACTUREN,
		// 			formBlocksCol1: [
		// 				{
		// 					type: 'facturen',
		// 					when: 'always',
		// 				},
		// 			],
		// 			formBlocksCol2: [],
		// 		},
		// 		{
		// 			title: 'Belangrijke documenten',
		// 			icon: Documents,
		// 			path: Paths.DASHBOARD_DOCUMENTEN,
		// 			formBlocksCol1: [
		// 				{
		// 					type: 'documenten',
		// 					when: 'always',
		// 				},
		// 			],
		// 			formBlocksCol2: [],
		// 		},
		// ],
	},
	[ContractType.OWNER]: { tabs: [] },
};
