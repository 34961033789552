import { useQuery } from '@tanstack/react-query';
import { PaymentEndpointsService } from '../../../Shared.Frontend/generated-payment/services/PaymentEndpointsService';

export const usePayment = (paymentReference?: string) => {
	const { data: payment, isLoading: isLoadingPayment, isError: isErrorPayment, isSuccess: isSuccessPayment } = useQuery({
		queryKey: ['payment', paymentReference],
		queryFn: () => PaymentEndpointsService.getPayment({ uniquePaymentIdentifier: paymentReference ?? '' }),
		enabled: !!paymentReference,
	});

	return {
		payment,
		isLoadingPayment,
		isErrorPayment,
		isSuccessPayment,
	};
};
