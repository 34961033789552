import { ALPHANUMERIC_WITH_PUNCTUATION_AND_NEWLINES } from 'constants/regex';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useRequestKeyFormSchema = () => {
	const { t } = useTranslation();
	return useMemo(
		() =>
			Yup.object().shape({
				keyType: Yup.string().required(t('Vul dit veld in.')),
				comment: Yup.string()
					.matches(ALPHANUMERIC_WITH_PUNCTUATION_AND_NEWLINES, {
						message: t('Dit veld bevat ongeldige karakters'),
						excludeEmptyString: true,
					})
					.required(t('Vul dit veld in.'))
					.max(2000, t('Maximaal 10 karakters', { count: 2000 })),
			}),
		[t],
	);
};
