import ButtonPrimary from 'components/button-primary/ButtonPrimary';
import { EVENT_NAME, FORM_NAME, trackEvent } from 'hooks/app/useGoogleTagManager';
import ArrowForward from 'assets/svg/arrow-forward.svg?react';
import Layout from 'components/layout/Layout';
import Typography from 'components/typography/Typography';
import { useGetContractInfo } from 'hooks/store/useContract';
import { useOnlineAfspraken } from 'hooks/store/useDataConfig';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useQueryParams from 'hooks/useQueryParams';
import { useConditionalRedirect } from 'hooks/useConditionalRedirect';
import { Paths } from 'constants/routes';
import { useContractToUse } from 'hooks/useContractToUse';
import { usePayment } from 'hooks/usePayment';
import SkeletonSwitch from 'components/skeleton-switch/SkeletonSwitch';
import TextSkeleton from 'components/skeleton/text-skeleton/TextSkeleton';

const RequestKeyConfirmation: FunctionComponent = () => {
	const { t } = useTranslation();

	const query = useQueryParams();
	const paymentReference = query.get('id') ?? undefined;
	const { payment, isLoadingPayment, isSuccessPayment } = usePayment(paymentReference);

	const contractId = query.get('contractid') ?? undefined;
	const { contract } = useGetContractInfo(contractId);

	useConditionalRedirect(contractId === undefined ? undefined : !!contractId, { failedRoute: Paths.DASHBOARD });

	const requestKeyLink = useOnlineAfspraken('sleutelaanvragen');

	const { contractToUse } = useContractToUse();

	useEffect(() => {
		if (!contractToUse?.type) return;
		trackEvent({
			event: EVENT_NAME.FORM_STEP,
			contracttype: contractToUse?.type,
			form_name: FORM_NAME.RequestKey,
			form_step_name: 'succes',
			form_step_number: 3,
		});
	}, [contractToUse?.type]);

	const title = useMemo(() => {
		switch (payment?.status) {
			case 'Completed':
				return t('Sleutel aanvragen is gelukt', { address: contract?.address ?? '' });
			case 'Cancelled':
				return t('Sleutel aanvragen is geannuleerd');
			case 'Unknown':
				return t('Sleutel aanvragen is mislukt');
			case 'Created':
			case 'Pending':
				return t('Sleutel aanvragen is in verwerking');
			default:
				return '';
		}
	}, [payment?.status, contract?.address, t]);

	const description = useMemo(() => {
		if (isSuccessPayment === true) {
			switch (payment?.status) {
				case 'Completed':
					return t(
						'Je hebt de sleutel voor Stadhuiskade 25 betaald. Je krijgt een e-mail zodra je de sleutel kan ophalen bij ons kantoor. Je kunt deze pagina nu veilig sluiten.',
						{ address: contract?.address ?? '' },
					);
				case 'Cancelled':
					return t(
						'Het betalen van de sleutel van Stadhuiskade 25 is geannuleerd. Je kunt deze pagina nu veilig sluiten.',
						{ address: contract?.address ?? '' },
					);
				case 'Created':
				case 'Pending':
					return t(
						'Het betalen van de sleutel van Stadhuiskade 25 is in verwerking. Je kunt deze pagina nu veilig sluiten.',
						{ address: contract?.address ?? '' },
					);
				case 'Unknown':
					return t(
						'Het betalen van de sleutel van Stadhuiskade 25 is niet gelukt. Probeer het nog een keer. Je kunt deze pagina nu veilig sluiten.',
						{ address: contract?.address ?? '' },
					);
				default:
					return '';
			}
		}

		return '';
	}, [isSuccessPayment, payment?.status, t, contract?.address]);

	return (
		<Layout title={title}>
			<SkeletonSwitch
				showContent={isLoadingPayment === false}
				skeleton={<TextSkeleton />}
			>
				<>
					<Typography variant='body'>
						{description}
					</Typography>

					{requestKeyLink && (
						<ButtonPrimary
							link={requestKeyLink}
							icon={ArrowForward}
						>
							<Trans>Maak online een afspraak</Trans>
						</ButtonPrimary>
					)}
				</>
			</SkeletonSwitch>
		</Layout>
	);
};

export default RequestKeyConfirmation;
