/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActionResponse } from '../models/ActionResponse';
import type { AddResidentRequest } from '../models/AddResidentRequest';
import type { AddResidentResponse } from '../models/AddResidentResponse';
import type { AdjustEnergyAdvanceRequest } from '../models/AdjustEnergyAdvanceRequest';
import type { CancellationRequest } from '../models/CancellationRequest';
import type { ContractDetailsResponse } from '../models/ContractDetailsResponse';
import type { ContractInfoResponse } from '../models/ContractInfoResponse';
import type { DirectDebitSettingsRequest } from '../models/DirectDebitSettingsRequest';
import type { DirectDebitSettingsResponse } from '../models/DirectDebitSettingsResponse';
import type { EnergyAdvanceInfo } from '../models/EnergyAdvanceInfo';
import type { NameTagRequest } from '../models/NameTagRequest';
import type { NameTagResponse } from '../models/NameTagResponse';
import type { NuisanceRequest } from '../models/NuisanceRequest';
import type { NuisanceResponse } from '../models/NuisanceResponse';
import type { RentIncrease } from '../models/RentIncrease';
import type { RentIncreaseoppositionRequest } from '../models/RentIncreaseoppositionRequest';
import type { RentReductionRequest } from '../models/RentReductionRequest';
import type { SubleaseRequest } from '../models/SubleaseRequest';
import type { SubleaseResponse } from '../models/SubleaseResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContractService {

    /**
     * @param contractId
     * @returns ContractDetailsResponse OK
     * @throws ApiError
     */
    public static contractDetails(
        contractId: number,
    ): CancelablePromise<ContractDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/contract/{contractId}',
            path: {
                'contractId': contractId,
            },
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @param contractId
     * @param request
     * @returns AddResidentResponse OK
     * @throws ApiError
     */
    public static contractAddResident(
        contractId: number,
        request: AddResidentRequest,
    ): CancelablePromise<AddResidentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stadswonen-portal/api/contract/{contractId}/add-resident',
            path: {
                'contractId': contractId,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                410: `Gone`,
                424: `Dependency Failed`,
                500: `InternalServerError`,
            },
        });
    }

    /**
     * @param contractId
     * @returns ActionResponse OK
     * @throws ApiError
     */
    public static contractRequestAgreement(
        contractId: number,
    ): CancelablePromise<ActionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/contract/{contractId}/agreement',
            path: {
                'contractId': contractId,
            },
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * Cancel specified contract
     * @param contractId
     * @param request
     * @returns any OK
     * @throws ApiError
     */
    public static contractCancel(
        contractId: number,
        request: CancellationRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stadswonen-portal/api/contract/{contractId}/cancel',
            path: {
                'contractId': contractId,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                410: `Not Available`,
                424: `Dependency Failed`,
                500: `Server error`,
            },
        });
    }

    /**
     * @param contractId
     * @param request
     * @returns NuisanceResponse OK
     * @throws ApiError
     */
    public static contractReportNuisance(
        contractId: number,
        request: NuisanceRequest,
    ): CancelablePromise<NuisanceResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stadswonen-portal/api/contract/{contractId}/report-nuisance',
            path: {
                'contractId': contractId,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                410: `Gone`,
                424: `Dependency Failed`,
                500: `InternalServerError`,
            },
        });
    }

    /**
     * @param contractId
     * @param request
     * @returns NameTagResponse OK
     * @throws ApiError
     */
    public static contractRequestNameTag(
        contractId: number,
        request: NameTagRequest,
    ): CancelablePromise<NameTagResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stadswonen-portal/api/contract/{contractId}/request-nametag',
            path: {
                'contractId': contractId,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                410: `Gone`,
                424: `Dependency Failed`,
                500: `InternalServerError`,
            },
        });
    }

    /**
     * @param contractId
     * @param request
     * @returns boolean OK
     * @throws ApiError
     */
    public static contractRequestRentReduction(
        contractId: number,
        request: RentReductionRequest,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stadswonen-portal/api/contract/{contractId}/request-rent-reduction',
            path: {
                'contractId': contractId,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                410: `Gone`,
                424: `Dependency Failed`,
                500: `InternalServerError`,
            },
        });
    }

    /**
     * @param contractId
     * @param request
     * @returns SubleaseResponse OK
     * @throws ApiError
     */
    public static contractRequestSublease(
        contractId: number,
        request: SubleaseRequest,
    ): CancelablePromise<SubleaseResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stadswonen-portal/api/contract/{contractId}/request-sublease',
            path: {
                'contractId': contractId,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                410: `Gone`,
                424: `Dependency Failed`,
                500: `InternalServerError`,
            },
        });
    }

    /**
     * @param contractId
     * @returns ActionResponse OK
     * @throws ApiError
     */
    public static contractEnableServiceContract(
        contractId: number,
    ): CancelablePromise<ActionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/contract/{contractId}/servicecontract',
            path: {
                'contractId': contractId,
            },
            errors: {
                401: `Unauthorized`,
                410: `Gone`,
                424: `Dependency Failed`,
                500: `InternalServerError`,
            },
        });
    }

    /**
     * @param request
     * @returns any OK
     * @throws ApiError
     */
    public static contractAdjustEnergyAdvance(
        request: AdjustEnergyAdvanceRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stadswonen-portal/api/contract/adjust-energy-advance',
            body: request,
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @param contractId
     * @returns DirectDebitSettingsResponse OK
     * @throws ApiError
     */
    public static contractGetDirectDebitsettings(
        contractId: number,
    ): CancelablePromise<DirectDebitSettingsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/contract/directDebitSettings',
            query: {
                'contractId': contractId,
            },
            errors: {
                401: `Unauthorized`,
                410: `Gone`,
                424: `Dependency Failed`,
                500: `InternalServerError`,
            },
        });
    }

    /**
     * @param request
     * @returns any OK
     * @throws ApiError
     */
    public static contractSetDirectDebitSettings(
        request: DirectDebitSettingsRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stadswonen-portal/api/contract/directDebitSettings',
            body: request,
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @param contractId
     * @returns EnergyAdvanceInfo OK
     * @throws ApiError
     */
    public static contractGetEnergyAdvanceInfo(
        contractId: number,
    ): CancelablePromise<EnergyAdvanceInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/contract/energy-advance-info',
            query: {
                'contractId': contractId,
            },
            errors: {
                401: `Unauthorized`,
                410: `Gone`,
                424: `Dependency Failed`,
                500: `InternalServerError`,
            },
        });
    }

    /**
     * Retrieve a list of contracts for a client
     * @returns ContractInfoResponse OK
     * @throws ApiError
     */
    public static contractList(): CancelablePromise<ContractInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/contract/list',
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @param request
     * @returns any OK
     * @throws ApiError
     */
    public static contractOpposeRentIncrease(
        request: RentIncreaseoppositionRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stadswonen-portal/api/contract/oppose-rent-increase',
            body: request,
            errors: {
                400: `BadRequest`,
                401: `Unauthorized`,
                410: `Gone`,
                424: `Dependency Failed`,
                500: `InternalServerError`,
            },
        });
    }

    /**
     * @param contractId
     * @returns RentIncrease OK
     * @throws ApiError
     */
    public static contractGetRentIncrease(
        contractId: number,
    ): CancelablePromise<RentIncrease> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/contract/rent-increase',
            query: {
                'contractId': contractId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @returns number OK
     * @throws ApiError
     */
    public static contractGetServiceContractPrice(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/contract/service-contract-price',
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

}
