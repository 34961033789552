/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnrollmentPaymentInfo } from '../models/EnrollmentPaymentInfo';
import type { EnrollmentRegistrationForm } from '../models/EnrollmentRegistrationForm';
import type { RegistrationDetails } from '../models/RegistrationDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EnrollmentService {

    /**
     * @param token
     * @returns any OK
     * @throws ApiError
     */
    public static enrollmentEnroll(
        token: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stadswonen-portal/api/enrollment/enroll',
            query: {
                'token': token,
            },
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static enrollmentIsEnrolled(): CancelablePromise<0 | 1 | 2 | 3> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/enrollment/enrolled',
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @returns RegistrationDetails OK
     * @throws ApiError
     */
    public static enrollmentGetRegistration(): CancelablePromise<RegistrationDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/enrollment/register',
            errors: {
                401: `Unauthorized`,
                404: `NotFound`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @param registration
     * @returns string OK
     * @throws ApiError
     */
    public static enrollmentRegister(
        registration: EnrollmentRegistrationForm,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stadswonen-portal/api/enrollment/register',
            body: registration,
            errors: {
                400: `BadRequest`,
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @param token
     * @returns EnrollmentPaymentInfo OK
     * @throws ApiError
     */
    public static enrollmentStartPayment(
        token: string,
    ): CancelablePromise<EnrollmentPaymentInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/enrollment/start-payment',
            query: {
                'token': token,
            },
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

}
