export enum RequestKeyType {
	UNKNOWN = 0,
	BUILDING_ENTRANCE = 1,
	CENTRAL_STORAGE = 2,
	FRONT_DOOR = 3,
	MAILBOX = 4,
	ELECTRONIC_KEY = 5
}

export interface GetRequestKeyResponse {
	deposit: number;
	price: number;
}

export interface RequestKeyPaymentParams {
	id: string;
	contractId: string;
}
