/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KeyPrices } from '../models/KeyPrices';
import type { MaintenanceReport } from '../models/MaintenanceReport';
import type { MaintenanceRequestRequest } from '../models/MaintenanceRequestRequest';
import type { RequestKeyRequest } from '../models/RequestKeyRequest';
import type { RescheduleAppointmentRequest } from '../models/RescheduleAppointmentRequest';
import type { Timeslot } from '../models/Timeslot';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MaintenanceService {

    /**
     * @param contractId
     * @param request
     * @returns boolean OK
     * @throws ApiError
     */
    public static maintenanceRequestMaintenance(
        contractId: number,
        request: MaintenanceRequestRequest,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stadswonen-portal/api/maintenance/{contractId}/request',
            path: {
                'contractId': contractId,
            },
            body: request,
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @returns KeyPrices OK
     * @throws ApiError
     */
    public static maintenanceGetKeyPrices(): CancelablePromise<KeyPrices> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/maintenance/keys/prices',
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @param request
     * @returns string OK
     * @throws ApiError
     */
    public static maintenanceRequestKeyPaymentId(
        request: RequestKeyRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stadswonen-portal/api/maintenance/keys/request',
            body: request,
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @param request
     * @returns boolean OK
     * @throws ApiError
     */
    public static maintenanceRequestKey(
        request: RequestKeyRequest,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stadswonen-portal/api/maintenance/keys/request-key',
            body: request,
            errors: {
                401: `Unauthorized`,
                410: `Gone`,
                424: `Dependency Failed`,
                500: `InternalServerError`,
            },
        });
    }

    /**
     * @param reportId
     * @returns MaintenanceReport OK
     * @throws ApiError
     */
    public static maintenanceReportById(
        reportId: number,
    ): CancelablePromise<MaintenanceReport> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/maintenance/report/{reportId}',
            path: {
                'reportId': reportId,
            },
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @returns MaintenanceReport OK
     * @throws ApiError
     */
    public static maintenanceReports(): CancelablePromise<Array<MaintenanceReport>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/maintenance/reports',
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @param request
     * @returns any OK
     * @throws ApiError
     */
    public static maintenanceReschedule(
        request: RescheduleAppointmentRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/stadswonen-portal/api/maintenance/reschedule',
            body: request,
            errors: {
                401: `Unauthorized`,
                406: `NotAcceptable`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @param year
     * @param month
     * @param day
     * @param reportId
     * @returns Timeslot OK
     * @throws ApiError
     */
    public static maintenanceTimeslots(
        year: number,
        month: number,
        day: number,
        reportId: number,
    ): CancelablePromise<Array<Timeslot>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/maintenance/timeslots',
            query: {
                'year': year,
                'month': month,
                'day': day,
                'reportId': reportId,
            },
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

}
