import Box from 'components/box/Box';
import ButtonPrimary from 'components/button-primary/ButtonPrimary';
import ArrowForward from 'assets/svg/arrow-forward.svg?react';
import Select from 'components/select/Select';
import Textarea from 'components/textarea/Textarea';
import Typography from 'components/typography/Typography';
import { useFormik } from 'formik';
import { RequestKeyType } from 'model/requestKey';
import { FunctionComponent, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRequestKeyForm } from './useRequestKeyForm';
import styles from './RequestKeyForm.module.scss';
import { useRequestKeyFormSchema } from './validationSchema';
import { formatNumber } from 'utils/numeral';
import { useGoogleTagManagerTrackEvent } from 'hooks/app/useGoogleTagManager';
import Spinner from 'assets/svg/spinner.svg?react';
import { useMutation } from '@tanstack/react-query';
import { KeyPrices } from '../../../../../Shared.Frontend/generated-portal-stadswonen/models/KeyPrices';
import safeWindow from 'utils/safeWindow';
import { useContractToUse } from 'hooks/useContractToUse';
import { ContractService, KeyType } from '../../../../../Shared.Frontend/generated-portal';

interface RequestKeyFormValues {
	keyType: 0 | 1 | 2 | 3 | 4 | 5 | '';
	comment?: string;
}

interface Props {
	data: KeyPrices | undefined,
	isSuccess: boolean
}

const RequestKeyForm: FunctionComponent<Props> = ({ data, isSuccess }) => {
	const { contractToUse } = useContractToUse();
	const validationSchema = useRequestKeyFormSchema();
	const [keyOptions] = useRequestKeyForm();
	const { t } = useTranslation();
	useGoogleTagManagerTrackEvent('event', 'Goal', 'request-key-form', 'Form start');

	const { data: requestKeyResponse, isSuccess: requestIsSuccess, mutate, isLoading: isLoading } = useMutation({ mutationFn: ContractService.postApiPortalKeysRequest });

	const {
		handleBlur,
		handleChange,
		handleSubmit,
		values,
		touched,
		errors,
	} = useFormik<RequestKeyFormValues>({
		validationSchema,
		initialValues: { keyType: '', comment: '' },
		onSubmit: (values) => {
			let keyType: KeyType | undefined;
			switch (Number(values.keyType)) {
				case RequestKeyType.BUILDING_ENTRANCE:
					keyType = 'BuildingEntrance';
					break;
				case RequestKeyType.ELECTRONIC_KEY:
					keyType = 'ElectronicKey';
					break;
				case RequestKeyType.CENTRAL_STORAGE:
					keyType = 'CentralStorage';
					break;
			}

			if (keyType) {
				mutate({
					requestBody: {
						brand: 'Stadswonen',
						contractId: Number(contractToUse?.id),
						extraInformation: values.comment,
						keyType: keyType,
					},
				});
			}
		},
	});

	useEffect(() => {
		if (requestIsSuccess && requestKeyResponse?.paymentUrl !== undefined) {
			if (safeWindow.location) {
				safeWindow.location.href = requestKeyResponse.paymentUrl;
			}
		}
	}, [requestIsSuccess, requestKeyResponse]);

	const deposit = data?.deposit;
	const price = data?.price;

	return (
		<Box className={styles.root}>
			<Typography
				type='h2'
				variant='block-title'
			>
				<Trans>Gegevens van mijn sleutel</Trans>
			</Typography>
			<form
				onSubmit={handleSubmit}
				className={styles.form}
				id='request-key-form'
			>
				<div className={styles.select}>
					<Typography
						variant='form-label'
						htmlFor='keyType'
					>
						<Trans>Wat voor soort sleutel wil je aanvragen?</Trans>
					</Typography>

					<Select
						id='keyType'
						testid='keyType'
						name='keyType'
						options={keyOptions}
						value={String(values.keyType)}
						onChange={handleChange}
						onBlur={handleBlur}
						placeholder={t('Kies een sleutel')}
						valid={touched.keyType === undefined ? undefined : errors.keyType === undefined}
						helperText={touched.keyType === undefined ? undefined : errors.keyType}
						className={styles.selectField}
					/>
				</div>

				{(Number(values.keyType) === RequestKeyType.FRONT_DOOR ||
					Number(values.keyType) === RequestKeyType.MAILBOX)
					&& (
						<>
							<Typography variant='body-small'>
								<Trans>
									Heb je helemaal geen sleutels meer?
								</Trans>
								{' '}
								<a
									href='https://www.stadswonenrotterdam.nl/Over-ons/Contact'
									target='_blank'
									rel='noopener noreferrer'
								>
									<Trans>Neem dan contact met ons op</Trans>
								</a>
								.
							</Typography>
						</>
					)}
				{(Number(values.keyType) === RequestKeyType.ELECTRONIC_KEY ||
					Number(values.keyType) === RequestKeyType.BUILDING_ENTRANCE ||
					Number(values.keyType) === RequestKeyType.CENTRAL_STORAGE)
					&& (
						<>
							<div className={styles.textarea}>
								<Typography
									variant='form-label'
									htmlFor='comment'
								>
									<Trans>Sleutelnummer</Trans>
								</Typography>

								<Textarea
									id='comment'
									name='comment'
									value={values.comment}
									onChange={handleChange}
									onBlur={handleBlur}
									valid={touched.comment === undefined ? undefined : errors.comment === undefined}
									helperText={touched.comment === undefined ? undefined : errors.comment}
									resize='vertical'
									placeholder={t('Geef hier het sleutelnummer op. Deze vind je op de sleutel.')}
									hideValidateIcon
									testid='Sleutelnummer'
								/>
							</div>
							{isSuccess === true && price !== undefined && deposit !== undefined && (
								<div className={styles.data}>
									<Typography variant='form-label'>
										<Trans>Kosten sleutel</Trans>
									</Typography>
									<Typography
										variant='form-label'
										id='request-key-price'
									>
										€{formatNumber(price)}
									</Typography>
									<Typography variant='form-label'>
										<Trans>Borgprijs voor sleutel</Trans>
									</Typography>
									<Typography
										variant='form-label'
										id='request-key-deposit'
									>
										€{formatNumber(deposit)}
									</Typography>
									<Typography
										variant='form-label'
										fontWeight='bold'
										id='request-key-total'
									>
										<Trans values={{ cost: formatNumber(Number(price) + Number(deposit)) }}>De totale kosten zijn € 25,00.</Trans>
									</Typography>
								</div>
							)}
							<ButtonPrimary
								icon={isLoading ? Spinner : ArrowForward}
								type='submit'
								data-gtm-category='User interaction'
								data-gtm-action='request-key-form'
								data-gtm-label='Nu aanvragen'
								disabled={isLoading}
								testid='aanvragen'
							>
								<Trans>Nu aanvragen</Trans>
							</ButtonPrimary>
						</>
					)}
			</form>
		</Box >
	);
};

export default RequestKeyForm;
