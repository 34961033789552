/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Payment } from '../models/Payment';
import type { StartPaymentRequest } from '../models/StartPaymentRequest';
import type { StartPaymentResponse } from '../models/StartPaymentResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentEndpointsService {

    /**
     * @returns StartPaymentResponse OK
     * @throws ApiError
     */
    public static initiatePayment({
requestBody,
}: {
requestBody: StartPaymentRequest,
}): CancelablePromise<StartPaymentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payment/initiate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns Payment OK
     * @throws ApiError
     */
    public static getPayment({
uniquePaymentIdentifier,
}: {
uniquePaymentIdentifier: string,
}): CancelablePromise<Payment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/payment/payment',
            query: {
                'uniquePaymentIdentifier': uniquePaymentIdentifier,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static verifyWebhookEndpoint(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/payment/webhook',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns any Accepted
     * @throws ApiError
     */
    public static webhookStadswonen(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payment/stadswonen/webhook',
        });
    }

    /**
     * @returns any Accepted
     * @throws ApiError
     */
    public static webhookWoonstad(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payment/woonstad/webhook',
        });
    }

}
