import { SelectOption } from 'components/select/Select';
import { RequestKeyType } from 'model/requestKey';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type UseRequestKeyForm = () => [SelectOption[]];

export const useRequestKeyForm: UseRequestKeyForm = () => {
	const { t } = useTranslation();
	const keyTypeOptions = useMemo(
		() => [
			{
				value: String(RequestKeyType.BUILDING_ENTRANCE),
				label: t('Portiek'),
			},
			{
				value: String(RequestKeyType.CENTRAL_STORAGE),
				label: t('Centrale bergingsdeur'),
			},
			{
				value: String(RequestKeyType.FRONT_DOOR),
				label: t('Voordeur'),
			},
			{
				value: String(RequestKeyType.MAILBOX),
				label: t('Brievenbus'),
			},
			{
				value: String(RequestKeyType.ELECTRONIC_KEY),
				label: t('Elektronisch'),
			},
		],
		[t],
	);

	return [keyTypeOptions];
};
