/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Brand } from '../models/Brand';
import type { KeyPricesResponse } from '../models/KeyPricesResponse';
import type { KeyRequest } from '../models/KeyRequest';
import type { KeyRequestResponse } from '../models/KeyRequestResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContractService {

    /**
     * @returns KeyPricesResponse OK
     * @throws ApiError
     */
    public static getApiPortalKeysPrices({
        brand,
    }: {
        brand?: Brand,
    }): CancelablePromise<KeyPricesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/portal/keys/prices',
            query: {
                'brand': brand,
            },
        });
    }

    /**
     * @returns KeyRequestResponse OK
     * @throws ApiError
     */
    public static postApiPortalKeysRequest({
        requestBody,
    }: {
        requestBody?: KeyRequest,
    }): CancelablePromise<KeyRequestResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/portal/keys/request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}
