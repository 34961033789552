import { safeEnv } from './safeEnv';

export const externalLink = {
	WEBSITE: {
		tst: {
			nl: 'https://www-tst.stadswonenrotterdam.nl',
			en: 'https://www-tst.stadswonenrotterdam.nl',
		},
		acc: {
			nl: 'https://www-acc.stadswonenrotterdam.nl',
			en: 'https://www-acc.stadswonenrotterdam.nl',
		},
		prd: {
			nl: 'https://www.stadswonenrotterdam.nl',
			en: 'https://www.stadswonenrotterdam.nl',
		},
	},
	REPARATION_REQUEST: {
		tst: {
			nl: 'https://www-tst.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen',
			en: 'https://www-tst.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen',
		},
		acc: {
			nl: 'https://www-acc.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen',
			en: 'https://www-acc.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen',
		},
		prd: {
			nl: 'https://www.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen',
			en: 'https://www.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen',
		},
	},
	REPARATION_REQUEST_BUSINESS: {
		tst: {
			nl: 'https://www-tst.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen',
			en: 'https://www-tst.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen',
		},
		acc: {
			nl: 'https://www-acc.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen',
			en: 'https://www-acc.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen',
		},
		prd: {
			nl: 'https://www.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen',
			en: 'https://www.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen',
		},
	},
	PAY_RENT: {
		tst: {
			nl: 'https://www-tst.woonstadrotterdam.nl/informatie-en-regelen/betalen-en-geldzaken/huur-betalen',
			en: 'https://www-tst.woonstadrotterdam.nl/informatie-en-regelen/betalen-en-geldzaken/huur-betalen',
		},
		acc: {
			nl: 'https://www-acc.woonstadrotterdam.nl/informatie-en-regelen/betalen-en-geldzaken/huur-betalen',
			en: 'https://www-acc.woonstadrotterdam.nl/informatie-en-regelen/betalen-en-geldzaken/huur-betalen',
		},
		prd: {
			nl: 'https://www.woonstadrotterdam.nl/informatie-en-regelen/betalen-en-geldzaken/huur-betalen',
			en: 'https://www.woonstadrotterdam.nl/informatie-en-regelen/betalen-en-geldzaken/huur-betalen',
		},
	},
	COMPLAINT_OR_ANNOYANCE: {
		tst: {
			nl: 'https://www.stadswonenrotterdam.nl/nl/klantenservice/in-en-om-je-woning/overlast',
			en: 'https://www.stadswonenrotterdam.nl/nl/klantenservice/in-en-om-je-woning/overlast',
		},
		acc: {
			nl: 'https://www.stadswonenrotterdam.nl/nl/klantenservice/in-en-om-je-woning/overlast',
			en: 'https://www.stadswonenrotterdam.nl/nl/klantenservice/in-en-om-je-woning/overlast',
		},
		prd: {
			nl: 'https://www.stadswonenrotterdam.nl/nl/klantenservice/in-en-om-je-woning/overlast',
			en: 'https://www.stadswonenrotterdam.nl/nl/klantenservice/in-en-om-je-woning/overlast',
		},
	},
	DISCLAIMER: {
		tst: {
			nl: 'https://www-tst.woonstadrotterdam.nl/api/Knowledge/Media/external/media/5dd55ed7c3f0cd1610bd75f2/file',
			en: 'https://www-tst.woonstadrotterdam.nl/api/Knowledge/Media/external/media/5dd55ed7c3f0cd1610bd75f2/file',
		},
		acc: {
			nl: 'https://www-acc.woonstadrotterdam.nl/api/Knowledge/Media/external/media/5dd55ed7c3f0cd1610bd75f2/file',
			en: 'https://www-acc.woonstadrotterdam.nl/api/Knowledge/Media/external/media/5dd55ed7c3f0cd1610bd75f2/file',
		},
		prd: {
			nl: 'https://www.woonstadrotterdam.nl/api/Knowledge/Media/external/media/5dd55ed7c3f0cd1610bd75f2/file',
			en: 'https://www.woonstadrotterdam.nl/api/Knowledge/Media/external/media/5dd55ed7c3f0cd1610bd75f2/file',
		},
	},
	SERVICE_SUBSCRIPTION: {
		tst: {
			nl: 'https://www-tst.woonstadrotterdam.nl/kennisbank-artikel/5d80eb9b0c51611318980906',
			en: 'https://www-tst.woonstadrotterdam.nl/kennisbank-artikel/5d80eb9b0c51611318980906',
		},
		acc: {
			nl: 'https://www-acc.woonstadrotterdam.nl/kennisbank-artikel/5d80eb9b0c51611318980906',
			en: 'https://www-acc.woonstadrotterdam.nl/kennisbank-artikel/5d80eb9b0c51611318980906',
		},
		prd: {
			nl: 'https://www.woonstadrotterdam.nl/kennisbank-artikel/5d80eb9b0c51611318980906',
			en: 'https://www.woonstadrotterdam.nl/kennisbank-artikel/5d80eb9b0c51611318980906',
		},
	},
	CANCEL_CONTRACT_INSTRUCTIONS: {
		tst: {
			nl: 'https://www.stadswonenrotterdam.nl/nl/klantenservice/opzeggen-of-doorverhuizen',
			en: 'https://www.stadswonenrotterdam.nl/nl/klantenservice/opzeggen-of-doorverhuizen',
		},
		acc: {
			nl: 'https://www.stadswonenrotterdam.nl/nl/klantenservice/opzeggen-of-doorverhuizen',
			en: 'https://www.stadswonenrotterdam.nl/nl/klantenservice/opzeggen-of-doorverhuizen',
		},
		prd: {
			nl: 'https://www.stadswonenrotterdam.nl/nl/klantenservice/opzeggen-of-doorverhuizen',
			en: 'https://www.stadswonenrotterdam.nl/nl/klantenservice/opzeggen-of-doorverhuizen',
		},
	},
	LOGIN_VVE: {
		tst: {
			nl: 'https://woonstadrotterdam.twinq.nl/',
			en: 'https://woonstadrotterdam.twinq.nl/',
		},
		acc: {
			nl: 'https://woonstadrotterdam.twinq.nl/',
			en: 'https://woonstadrotterdam.twinq.nl/',
		},
		prd: {
			nl: 'https://woonstadrotterdam.twinq.nl/',
			en: 'https://woonstadrotterdam.twinq.nl/',
		},
	},
	LOGIN_PARTNER: {
		tst: {
			nl: 'https://wocas.woonstadrotterdam.nl/',
			en: 'https://wocas.woonstadrotterdam.nl/',
		},
		acc: {
			nl: 'https://wocas.woonstadrotterdam.nl/',
			en: 'https://wocas.woonstadrotterdam.nl/',
		},
		prd: {
			nl: 'https://wocas.woonstadrotterdam.nl/',
			en: 'https://wocas.woonstadrotterdam.nl/',
		},
	},
	LOGIN_EMPLOYEE: {
		tst: {
			nl: 'https://www-tst.woonstadrotterdam.nl/contact/extranet',
			en: 'https://www-tst.woonstadrotterdam.nl/contact/extranet',
		},
		acc: {
			nl: 'https://www-acc.woonstadrotterdam.nl/contact/extranet',
			en: 'https://www-acc.woonstadrotterdam.nl/contact/extranet',
		},
		prd: {
			nl: 'https://www.woonstadrotterdam.nl/contact/extranet',
			en: 'https://www.woonstadrotterdam.nl/contact/extranet',
		},
	},
	CAMPAIGN: {
		tst: {
			nl: 'https://www-tst.stadswonenrotterdam.nl/Ik-zoek/een-woning#!filter:(campaign:(kameractie))',
			en: 'https://www-tst.stadswonenrotterdam.nl/Ik-zoek/een-woning#!filter:(campaign:(kameractie))',
		},
		acc: {
			nl: 'https://www-acc.stadswonenrotterdam.nl/Ik-zoek/een-woning#!filter:(campaign:(kameractie))',
			en: 'https://www-tst.stadswonenrotterdam.nl/Ik-zoek/een-woning#!filter:(campaign:(kameractie))',
		},
		prd: {
			nl: 'https://www.stadswonenrotterdam.nl/Ik-zoek/een-woning#!filter:(campaign:(kameractie))',
			en: 'https://www.stadswonenrotterdam.nl/Ik-zoek/een-woning#!filter:(campaign:(kameractie))',
		},
	},
	SUBLEASE: {
		tst: {
			nl: 'https://www-tst.stadswonenrotterdam.nl/nl/service-contact/contracten-en-afspraken/onderverhuren',
			en: 'https://www-tst.stadswonenrotterdam.nl/nl/service-contact/contracten-en-afspraken/onderverhuren',
		},
		acc: {
			nl: 'https://www-acc.stadswonenrotterdam.nl/nl/service-en-contact/contracten-en-afspraken/onderverhuren',
			en: 'https://www-acc.stadswonenrotterdam.nl/nl/service-en-contact/contracten-en-afspraken/onderverhuren',
		},
		prd: {
			nl: 'https://www.stadswonenrotterdam.nl/nl/klantenservice/contracten-en-afspraken/onderverhuren',
			en: 'https://www.stadswonenrotterdam.nl/nl/klantenservice/contracten-en-afspraken/onderverhuren',
		},
	},
	HOME_VALUATION_NEWS_INFORMATION: {
		tst: {
			nl: 'https://www.woonstadrotterdam.nl/nieuws/wat-is-woningwaardering',
			en: 'https://www.woonstadrotterdam.nl/nieuws/wat-is-woningwaardering',
		},
		acc: {
			nl: 'https://www.woonstadrotterdam.nl/nieuws/wat-is-woningwaardering',
			en: 'https://www.woonstadrotterdam.nl/nieuws/wat-is-woningwaardering',
		},
		prd: {
			nl: 'https://www.woonstadrotterdam.nl/nieuws/wat-is-woningwaardering',
			en: 'https://www.woonstadrotterdam.nl/nieuws/wat-is-woningwaardering',
		},
	},
	WA_WOCO_MAINTENANCE: {
		tst: {
			nl: 'https://reparatieverzoekstadswonen.cedcloud.com/#/report/personalInfo',
			en: 'https://reparatieverzoekstadswonen.cedcloud.com/#/report/personalInfo',
		},
		acc: {
			nl: 'https://reparatieverzoekstadswonen.cedcloud.com/#/report/personalInfo',
			en: 'https://reparatieverzoekstadswonen.cedcloud.com/#/report/personalInfo',
		},
		prd: {
			nl: 'https://reparatieverzoekstadswonen.cedcloud.com/#/report/personalInfo',
			en: 'https://reparatieverzoekstadswonen.cedcloud.com/#/report/personalInfo',
		},
	},
	RENTAL_AGREMENT_CHANGES: {
		tst: {
			nl: 'https://www-tst.stadswonenrotterdam.nl/FAQ/wijzigingen-verhuurbeleid/',
			en: 'https://www-tst.stadswonenrotterdam.nl/FAQ/wijzigingen-verhuurbeleid/',
		},
		acc: {
			nl: 'https://www-acc.stadswonenrotterdam.nl/FAQ/wijzigingen-verhuurbeleid/',
			en: 'https://www-acc.stadswonenrotterdam.nl/FAQ/wijzigingen-verhuurbeleid/',
		},
		prd: {
			nl: 'https://www.stadswonenrotterdam.nl/FAQ/wijzigingen-verhuurbeleid/',
			en: 'https://www.stadswonenrotterdam.nl/FAQ/wijzigingen-verhuurbeleid/',
		},
	},
	MAINTENANCE_GUIDES: {
		tst: {
			nl: 'https://www-tst.stadswonenrotterdam.nl/Over-ons/voorwaarden',
			en: 'https://www-tst.stadswonenrotterdam.nl/en/About-us/Downloads',
		},
		acc: {
			nl: 'https://www-acc.stadswonenrotterdam.nl/Over-ons/voorwaarden',
			en: 'https://www-acc.stadswonenrotterdam.nl/en/About-us/Downloads',
		},
		prd: {
			nl: 'https://www.stadswonenrotterdam.nl/Over-ons/voorwaarden',
			en: 'https://www.stadswonenrotterdam.nl/en/About-us/Downloads',
		},
	},
	INFO: {
		tst: {
			nl: 'https://www-tst.woonstadrotterdam.nl/informatie-en-regelen',
			en: 'https://www-tst.woonstadrotterdam.nl/informatie-en-regelen',
		},
		acc: {
			nl: 'https://www-acc.woonstadrotterdam.nl/informatie-en-regelen',
			en: 'https://www-acc.woonstadrotterdam.nl/informatie-en-regelen',
		},
		prd: {
			nl: 'https://www.woonstadrotterdam.nl/informatie-en-regelen',
			en: 'https://www.woonstadrotterdam.nl/informatie-en-regelen',
		},
	},
	OFFERS: {
		tst: {
			nl: 'https://www-tst.stadswonenrotterdam.nl/Ik-zoek/een-woning',
			en: 'https://www-tst.stadswonenrotterdam.nl/en/Im-looking-for/A-home',
		},
		acc: {
			nl: 'https://www-acc.stadswonenrotterdam.nl/Ik-zoek/een-woning',
			en: 'https://www-acc.stadswonenrotterdam.nl/en/Im-looking-for/A-home',
		},
		prd: {
			nl: 'https://www.stadswonenrotterdam.nl/Ik-zoek/een-woning',
			en: 'https://www.stadswonenrotterdam.nl/en/Im-looking-for/A-home',
		},
	},
	CONTACT: {
		tst: {
			nl: 'https://www-tst.stadswonenrotterdam.nl/nl/klantenservice',
			en: 'https://www-tst.stadswonenrotterdam.nl/nl/klantenservice',
		},
		acc: {
			nl: 'https://www-acc.stadswonenrotterdam.nl/nl/klantenservice',
			en: 'https://www-acc.stadswonenrotterdam.nl/nl/klantenservice',
		},
		prd: {
			nl: 'https://www.stadswonenrotterdam.nl/nl/klantenservice',
			en: 'https://www.stadswonenrotterdam.nl/nl/klantenservice',
		},
	},
	PRIVACY_STATEMENT: {
		tst: {
			nl: 'https://www-tst.stadswonenrotterdam.nl/privacy',
			en: 'https://www-tst.stadswonenrotterdam.nl/privacy',
		},
		acc: {
			nl: 'https://www-acc.stadswonenrotterdam.nl/privacy',
			en: 'https://www-acc.stadswonenrotterdam.nl/privacy',
		},
		prd: {
			nl: 'https://www.stadswonenrotterdam.nl/privacy',
			en: 'https://www.stadswonenrotterdam.nl/en/privacy',
		},
	},
	USER_CONSENT_DISCLAIMER: {
		tst: {
			nl: 'https://www-tst.stadswonenrotterdam.nl/disclaimer',
			en: 'https://www-tst.stadswonenrotterdam.nl/en/disclaimer',
		},
		acc: {
			nl: 'https://www-acc.stadswonenrotterdam.nl/disclaimer',
			en: 'https://www-acc.stadswonenrotterdam.nl/en/disclaimer',
		},
		prd: {
			nl: 'https://www.stadswonenrotterdam.nl/disclaimer',
			en: 'https://www.stadswonenrotterdam.nl/en/disclaimer',
		},
	},
	MOVE: {
		tst: {
			nl: 'https://www-tst.stadswonenrotterdam.nl/Ik-huur/doorverhuizen',
			en: 'https://www-tst.stadswonenrotterdam.nl/en/I-am-a-tenant/moving',
		},
		acc: {
			nl: 'https://www-acc.stadswonenrotterdam.nl/Ik-huur/doorverhuizen',
			en: 'https://www-acc.stadswonenrotterdam.nl/en/I-am-a-tenant/moving',
		},
		prd: {
			nl: 'https://www.stadswonenrotterdam.nl/Ik-huur/doorverhuizen',
			en: 'https://www.stadswonenrotterdam.nl/en/I-am-a-tenant/moving',
		},
	},
	OFFER_AND_WAITING_TIMES: {
		tst: {
			nl: 'https://www-tst.stadswonenrotterdam.nl/FAQ/Aanbod-en-wachttijden',
			en: 'https://www-tst.stadswonenrotterdam.nl/FAQ/Aanbod-en-wachttijden',
		},
		acc: {
			nl: 'https://www-acc.stadswonenrotterdam.nl/FAQ/Aanbod-en-wachttijden',
			en: 'https://www-acc.stadswonenrotterdam.nl/FAQ/Aanbod-en-wachttijden',
		},
		prd: {
			nl: 'https://www.stadswonenrotterdam.nl/FAQ/Aanbod-en-wachttijden',
			en: 'https://www.stadswonenrotterdam.nl/FAQ/Aanbod-en-wachttijden',
		},
	},
	FAST_TRACKED: {
		tst: {
			nl: 'https://www.stadswonenrotterdam.nl/Hoe-werkt-het/urgentie-aanvragen',
			en: 'https://www.stadswonenrotterdam.nl/en/how-it-works/fast-tracked-accommodation',
		},
		acc: {
			nl: 'https://www.stadswonenrotterdam.nl/Hoe-werkt-het/urgentie-aanvragen',
			en: 'https://www.stadswonenrotterdam.nl/en/how-it-works/fast-tracked-accommodation',
		},
		prd: {
			nl: 'https://www.stadswonenrotterdam.nl/Hoe-werkt-het/urgentie-aanvragen',
			en: 'https://www.stadswonenrotterdam.nl/en/how-it-works/fast-tracked-accommodation',
		},
	},
	INWONER_VERTREKT: {
		tst: {
			nl: 'https://www.rotterdam.nl/verhuizing-doorgeven',
			en: 'https://www.rotterdam.nl/verhuizing-doorgeven',
		},
		acc: {
			nl: 'https://www.rotterdam.nl/verhuizing-doorgeven',
			en: 'https://www.rotterdam.nl/verhuizing-doorgeven',
		},
		prd: {
			nl: 'https://www.rotterdam.nl/verhuizing-doorgeven',
			en: 'https://www.rotterdam.nl/verhuizing-doorgeven',
		},
	},
};

type ExternalLinkKeys = keyof typeof externalLink;

export const getExternalLink = (link: ExternalLinkKeys, lang?: string, env?: string): string => {
	const linkLanguage = lang ? lang === 'nl-NL' ? 'nl' : 'en' : 'nl';

	switch (safeEnv('ENVIRONMENT') || env) {
		case 'Production':
			return externalLink[link].prd[linkLanguage];
		case 'Acceptance':
			return externalLink[link].acc[linkLanguage];
		case 'Test':
			return externalLink[link].tst[linkLanguage];
		default:
			return externalLink[link].prd[linkLanguage];
	}
};
