export const Paths = {
	DASHBOARD: '/',
	DASHBOARD_RUIMTE: '/in-en-om-mijn-woonruimte',
	DASHBOARD_PARKEERPLAATS: '/in-en-om-mijn-parkeerplaats',
	DASHBOARD_HUISHOUDEN: '/huishouden',
	DASHBOARD_HUUR_BETALINGEN: '/huur-en-betalingen',
	DASHBOARD_DOCUMENTEN: '/documenten',

	LOGIN_B2C: '/login-b2c',
	LOGIN_SIMPLE_B2C: '/login-simple-b2c',
	FORGOT_PASSWORD_B2C: '/forgot-password-b2c',
	CHANGE_PASSWORD_B2C: '/change-password-b2c',
	PROFILE_UPDATE_B2C: '/profile-update-b2c',
	LOGGED_OUT: '/logged-out',
	LOGIN_FAILED: '/login-failed',
	REGISTER_B2C: '/register-b2c',
	INVITATION_B2C: '/invitation-b2c',

	REGISTER: '/registreren',
	REGISTER_CONFIRMATION: '/registreren/succes',

	REGISTER_EXISTING_REGISTATION: '/bestaande-inschrijving',
	REGISTER_EXISTING_REGISTATION_CONFIRMATION: '/bestaande-inschrijving/succes',
	REGISTER_EXISTING_REGISTATION_ACTIVATE: '/bestaande-inschrijving/activeer-account',
	REGISTER_EXISTING_REGISTATION_ACTIVATE_CONFIRMATION: '/bestaande-inschrijving/activeer-account/succes',
	REGISTER_EXISTING_REGISTATION_ACTIVATE_INVALID: '/bestaande-inschrijving/activeer-account/mislukt',

	ACTIVATE_SIGNUP: '/activeer-inschrijving',
	ACTIVATE_SIGNUP_CONFIRMATION: '/activeer-inschrijving/succes',
	ACTIVATE_SIGNUP_INVALID: '/activeer-inschrijving/mislukt',

	CHOOSE_ACCOUNT: '/kies-gebruiker',

	NOTIFICATIONS: '/meldingen',

	REACTIONS: '/reacties',
	SUBMIT_REACTION: '/reageren/:advertisementUrl',
	SUBMIT_REACTION_CONFIRMATION: '/reageren/:advertisementUrl/succes',
	SUBMIT_REACTION_ERROR: '/reageren/:advertisementUrl/mislukt',

	ACTIVATE_REGISTRATION: '/activeer-registratie',
	ACTIVATE_REGISTRATION_CONFIRMATION: '/activeer-registratie/succes',
	ACTIVATE_REGISTRATION_INVALID: '/activeer-registratie/mislukt',

	SIGN_UP: '/inschrijven',
	SIGN_UP_CONFIRMATION: '/inschrijven/succes',
	SIGNUP_WELCOME: '/inschrijven/welkom',
	SIGNUP_DETAILS: '/inschrijven/gegevens-aanvullen',

	USER_PROFILE: '/mijn-gegevens',

	REQUEST_URGENCY: '/urgentie-aanvragen',
	REQUEST_URGENCY_PAYMENT_RETURN: '/urgentie-aanvragen/betaling',

	ADD_ROOMMATE: '/medebewoner-aanmelden',
	ADD_ROOMMATE_CONFIRMATION: '/medebewoner-aanmelden/succes',
	ADD_ROOMMATE_ERROR: '/medebewoner-aanmelden/mislukt',

	INWONER_VERTREKT: '/inwoner-vertrekt',
	INWONER_VERTREKT_SUCCESS: '/inwoner-vertrekt/succes',
	INWONER_VERTREKT_ERROR: '/inwoner-vertrekt/mislukt',

	REQUEST_KEY: '/sleutel-aanvragen',
	REQUEST_KEY_PAYMENT_RETURN: '/sleutel-aanvragen/betaling',

	SLEUTEL_AANVRAGEN: '/sleutel-aanvragen-new',
	SLEUTEL_AANVRAGEN_DETAILS: '/sleutel-aanvragen-new/details',
	SLEUTEL_AANVRAGEN_BETALING_IN_BEHANDELING: '/sleutel-aanvragen-new/betaling-in-behandeling',
	SLEUTEL_AANVRAGEN_BETALING_ERROR: '/sleutel-aanvragen-new/betaling-mislukt',
	SLEUTEL_AANVRAGEN_SUCCESS: '/sleutel-aanvragen-new/succes',
	SLEUTEL_AANVRAGEN_ERROR: '/sleutel-aanvragen-new/mislukt',

	PAYMENT_PLAN: '/betalingsregeling-treffen',
	PAYMENT_PLAN_SUCCESS: '/betalingsregeling-treffen/succes',
	PAYMENT_PLAN_ERROR: '/betalingsregeling-treffen/mislukt',

	CANCEL_CONTRACT: '/huur-opzeggen',
	CANCEL_CONTRACT_CONFIRMATION: '/huur-opzeggen/succes',
	CANCEL_CONTRACT_ERROR: '/huur-opzeggen/mislukt',

	HOME_VALUATION_POINTS: '/woning-waarderingspunten',

	CHANGE_DIRECT_DEBIT_DATE: '/flexibele-incasso',
	CHANGE_DIRECT_DEBIT_DATE_CONFIRMATION: '/flexibele-incasso/succes',
	CHANGE_DIRECT_DEBIT_DATE_ERROR: '/flexibele-incasso/mislukt',

	PAYMENT_RETURN: '/betaling',

	REQUEST_SUBLEASE: '/aanvragen-onderhuur',
	REQUEST_SUBLEASE_SUCCESS: '/aanvragen-onderhuur/succes',
	REQUEST_SUBLEASE_ERROR: '/aanvragen-onderhuur/mislukt',

	CAMPAIGN: '/kamer-actie',
	CAMPAIGN_SUCCESS: '/kamer-actie/aanmelden-succes',
	CAMPAIGN_ERROR: '/kamer-actie/aanmelden-mislukt',
	CAMPAIGN_UNQUALIFIED: '/kamer-actie/niet-in-aanmerking',
	CAMPAIGN_ALREADY_SUBSCRIBED: '/kamer-actie/je-bent-al-aangemeld',

	MAINTENANCE: '/reparatie-verzoek',

	OVERLAST_MELDEN: '/overlast-melden',
	OVERLAST_MELDEN_SUCCESS: '/overlast-melden/succes',
	OVERLAST_MELDEN_ERROR: '/overlast-melden/mislukt',

	GENERAL_CONTACT: '/vragen-opmerkingen-klachten',
	GENERAL_CONTACT_SUCCESS: '/vragen-opmerkingen-klachten/succes',
	GENERAL_CONTACT_ERROR: '/vragen-opmerkingen-klachten/mislukt',

	SIGN_UP_PAYMENT: '/inschrijving-betalen',

	MAIL_PREFERENCES: '/emailvoorkeuren-wijzigen',

	PROVIDE_ADVERTISEMENT_DETAILS: '/advertentiedetails-aanleveren',
	PROVIDE_ADVERTISEMENT_DETAILS_CONFIRMATION: '/advertentiedetails-aanleveren/succes',
	PROVIDE_ADVERTISEMENT_DETAILS_ERROR: '/advertentiedetails-aanleveren/mislukt',

	RENT_INCREASE_OBJECTION: '/bezwaar-huurverhoging',
	RENT_INCREASE_OBJECTION_CONFIRMATION: '/bezwaar-huurverhoging/succes',
	RENT_INCREASE_OBJECTION_ERROR: '/bezwaar-huurverhoging/mislukt',

	RENTAL_REFERENCE_LETTER: '/verhuurdersverklaring-aanvragen',
	RENTAL_REFERENCE_LETTER_START: '/verhuurdersverklaring-aanvragen/start',
	RENTAL_REFERENCE_LETTER_SUCCESS: '/verhuurdersverklaring-aanvragen/succes',
	RENTAL_REFERENCE_LETTER_ERROR: '/verhuurdersverklaring-aanvragen/mislukt',

	HUURCONTRACT_KOPPELEN: '/huurcontract-koppelen',
	HUURCONTRACT_KOPPELEN_CONFIRMATION: '/huurcontract-koppelen/succes',

	CHANGE_EMAIL_MULTI_USER: '/emailadres-aanpassen',
	CHANGE_EMAIL_MULTI_USER_CONFIRMATION: '/emailadres-aanpassen/succes',

	NO_ACCESS: '/geen-toegang',

	NOT_FOUND: '/not-found',

	DOCUMENT_VIEW: '/document-view',

	CREATE_ACCOUNT: '/account-aanmaken',

	WOONFRAUDE_MELDEN: '/woonfraude-melden',
	WOONFRAUDE_MELDEN_SUCCESS: '/woonfraude-melden/succes',
	WOONFRAUDE_MELDEN_ERROR: '/woonfraude-melden/mislukt',

	ONGEDIERTE_MELDEN: '/ongedierte-melden',
	ONGEDIERTE_MELDEN_SUCCESS: '/ongedierte-melden/success',
	ONGEDIERTE_MELDEN_ERROR: '/ongedierte-melden/error',

	ZORG_OM_JE_BUUR: '/zorg-om-je-buur',
	ZORG_OM_JE_BUUR_SUCCESS: '/zorg-om-je-buur/succes',
	ZORG_OM_JE_BUUR_ERROR: '/zorg-om-je-buur/mislukt',

	CHANGE_IBAN: '/rekeningnummer-wijzigen',
	CHANGE_IBAN_SUCCESS: '/rekeningnummer-wijzigen/succes',
	CHANGE_IBAN_ERROR: '/rekeningnummer-wijzigen/mislukt',

	HUURDER_VERTREKT: '/huurder-vertrekt',
	HUURDER_VERTREKT_SUCCESS: '/huurder-vertrekt/succes',
	HUURDER_VERTREKT_ERROR: '/huurder-vertrekt/mislukt',

} as const;

export const allRoutes = Object.values(Paths).map((path) => path);
