import clsx from 'clsx';
import HelperText from 'components/helper-text/HelperText';
import Error from 'assets/svg/error.svg?react';
import Success from 'assets/svg/success.svg?react';
import {
	ChangeEvent,
	ComponentPropsWithoutRef,
	ElementType,
	FocusEvent,
	forwardRef,
	useCallback,
	useMemo,
	useState,
} from 'react';
import './Textarea.scss';

export interface TextareaProps extends ComponentPropsWithoutRef<ElementType> {
	value?: string;
	className?: string;
	placeholder?: string;
	id?: string;
	testid?: string;
	name?: string;
	disabled?: boolean;
	disableAutocomplete?: boolean;
	valid?: boolean;
	helperText?: string;
	hideValidateIcon?: boolean;
	resize?: 'both' | 'vertical' | 'horizontal';
	rows?: number;
	onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
	onBlur?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
	onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void;
}

const Textarea = forwardRef<HTMLElement, TextareaProps>((
	{
		value,
		className,
		placeholder,
		id,
		testid,
		rows = 3,
		name,
		onChange,
		valid,
		helperText,
		hideValidateIcon,
		onBlur,
		onFocus,
		disableAutocomplete = false,
		disabled = false,
		resize,
	},
	ref,
) => {
	const [focus, setFocus] = useState<boolean>(false);
	const onInnerBlur = useCallback(
		(event: ChangeEvent<HTMLTextAreaElement>) => {
			setFocus(false);
			if (onBlur !== undefined) {
				onBlur(event);
			}
		},
		[onBlur],
	);
	const onInnerFocus = useCallback(
		(event: FocusEvent<HTMLTextAreaElement>) => {
			setFocus(true);
			if (onFocus !== undefined) {
				onFocus(event);
			}
		},
		[onFocus],
	);
	const classes = clsx('textarea', className, {
		'textarea--focus': focus,
		'textarea--disabled': disabled,
		'textarea--success': valid !== undefined && valid,
		'textarea--error': valid !== undefined && !valid,
		[`textarea--resize-${resize}`]: resize !== undefined && resize !== 'both',
		'textarea--has-validation-icon': !hideValidateIcon && valid !== undefined,
	});

	const helperTextView = useMemo(() => {
		if (helperText !== undefined) {
			const status = valid === undefined ? undefined : valid ? 'success' : 'error';
			const classes = clsx('textarea__helper-text', {
				[`textarea__helper-text--${status}`]: status !== undefined,
			});
			return (
				<HelperText
					className={classes}
					id={id}
				>
					{helperText}
				</HelperText>
			);
		}

		return undefined;
	}, [helperText, valid, id]);
	return (
		<span
			className={classes}
			ref={ref}
		>
			<span className='textarea__inner'>
				<textarea
					rows={rows}
					id={id}
					name={name}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					className='textarea__field'
					onFocus={onInnerFocus}
					onBlur={onInnerBlur}
					disabled={disabled}
					autoComplete={disableAutocomplete ? 'off' : 'on'}
					data-testid={testid}
				/>

				{!hideValidateIcon && valid !== undefined && valid && <Success className='textarea__validation-icon' />}
				{!hideValidateIcon && valid !== undefined && !valid && <Error className='textarea__validation-icon' />}
			</span>
			{helperTextView}
		</span>
	);
});

Textarea.displayName = 'Textarea';

export default Textarea;
